import { Button, Tag, Text } from '@chakra-ui/react';
import { InlineText } from 'components/InlineText';
import { usePurchaseOrderContact } from 'hooks/usePurchaseOrderContact';
import { useNavigate } from 'react-router-dom';
import { PurchaseOrderPreview } from 'types/purchaseOrders';
import { AccordionPurchaseOrders } from './AccordionPurchaseOrders';

export type Props = {
  data: PurchaseOrderPreview[];
};

export function SuccessUploadPurchaseOrders({ data }: Props) {
  const navigate = useNavigate();
  const { copy, url } = usePurchaseOrderContact(data.map((po) => po.poNumber!));

  return (
    <>
      <Text
        fontWeight={500}
        display="flex"
        alignItems="center"
        flexWrap="wrap"
        gap={2}
      >
        <Tag
          as="span"
          size="lg"
          fontSize="lg"
          variant="solid"
          colorScheme="green"
        >
          Success
        </Tag>
        {data.length} shipping plans were successfully created
      </Text>
      <AccordionPurchaseOrders data={data} />
      <Button
        size="md"
        variant="solid"
        colorScheme="primaryBlue"
        onClick={() => navigate('/inbound-inventory')}
      >
        View Shipping Plans
      </Button>
      <Text as="h3" variant="pageHeading" my={1}>
        5. Contact Transportation
      </Text>
      <Text fontWeight={500}>
        Contact the Standvast transportation team{' '}
        <InlineText fontWeight="700">at least 2 business days</InlineText>
        <br />
        before the requested delivery date to coordinate arrival details
      </Text>
      <Button
        as="a"
        size="md"
        colorScheme="primaryBlue"
        target="_blank"
        href={url}
      >
        Contact
      </Button>
      <Text fontWeight={500}>
        Contact us yourself or copy this link and share it with your carrier
      </Text>
      <Button size="md" colorScheme="primaryBlue" onClick={copy}>
        Copy
      </Button>
    </>
  );
}
