export const WAREHOUSE_TIME_ZONES = [
  {
    warehouseCode: 'RNO',
    timezone: 'America/Los_Angeles',
  },
  {
    warehouseCode: 'MEM',
    timezone: 'America/Chicago',
  },
  {
    warehouseCode: 'BWI',
    timezone: 'America/New_York',
  },
];

export const PURCHASE_ORDER_DELIVERY_DAYS = 1;
