import { Box, Text } from '@chakra-ui/react';
import { UploadButton } from 'components/UploadButton';
import { useUploadMsaContext } from 'hooks/useUploadMsa';
import { FileType } from 'utils/fileTypes';
import { useToast } from 'hooks/useToast';

export function SelectMsaFile() {
  const toast = useToast();
  const { file, setFile } = useUploadMsaContext();

  const fileChange = (file?: File) => {
    if (file && file.type !== FileType.Pdf) {
      toast({
        status: 'error',
        title: 'Error',
        description: 'Invalid file format. Please upload a PDF.',
      });
    } else {
      setFile(file);
    }
  };

  return (
    <Box
      display="flex"
      flexDir={['column', 'row']}
      justifyContent={['flex-start', 'center']}
      alignItems={['flex-start', 'center']}
      py={6}
    >
      <Text fontWeight={500} mr={[0, 1, 3]} mb={[3, 0]}>
        Select file to upload:
      </Text>

      <UploadButton
        data-testid="msaUpload"
        defaultValue={file}
        label="Choose file"
        accept=".pdf"
        onChange={fileChange}
      />
    </Box>
  );
}
