/* istanbul ignore file */
import * as yup from 'yup';

export const basicNetworkOrderInfo = yup
  .object({
    name: yup.string().label('Order #').required().max(50),
    poNumber: yup
      .string()
      .label('Purchase Order #')
      .max(150)
      .nullable()
      .optional(),
    instructions: yup
      .string()
      .label('Instructions')
      .max(250)
      .nullable()
      .optional(),
    shipVia: yup.string().label('Ship Via').required().max(50),
    giftMessage: yup
      .string()
      .optional()
      .nullable()
      .max(500)
      .label('Gift Message'),
  })
  .required();
