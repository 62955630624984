import { Text, useBreakpointValue, ToastPosition, Box } from '@chakra-ui/react';
import { AxiosError } from 'axios';
import { CopyableEmail } from 'components/CopyableEmail';
import { Prompt } from 'components/Prompt';
import { useCrudActionUserFeedback } from 'hooks/useCrudActionUserFeedback';
import { useCancelSalesOrder } from 'mutations/salesOrders';
import { SalesOrder } from 'types/salesOrders';
import { sendConfirmCancelSalesOrderEvent } from 'utils/monitorEvents';
import { needsApprovalToBeCancelled } from 'utils/salesOrdersUtils';
import { useToast } from 'hooks/useToast';

type Props = {
  order: SalesOrder;
  onAbort: () => void;
  onConfirm: () => void;
};

const UNPROCESSABLE_ENTITY_ERROR_CODE = 422;

export function CancelOrderPrompt({ order, onAbort, onConfirm }: Props) {
  const toastPosition: ToastPosition = useBreakpointValue(
    {
      base: 'top',
      md: 'top-right',
    },
    {
      fallback: 'top-right',
    }
  )!;
  const { mutateAsync, isLoading } = useCancelSalesOrder();
  const toast = useToast({ position: toastPosition });

  const needsApproval = needsApprovalToBeCancelled(order);
  const handleCancelOrder = useCrudActionUserFeedback<SalesOrder>({
    mutateAsync: () => mutateAsync(order.soNumber),
    actionType: 'DELETE',
    successMessage: `Shipment #${order.soNumber} deleted`,
    successCallback: () => {
      sendConfirmCancelSalesOrderEvent(order.soNumber);
      onConfirm();
    },
    customErrorHandler: (err: AxiosError) => {
      if (err.response?.status === UNPROCESSABLE_ENTITY_ERROR_CODE) {
        toast({
          status: 'success',
          title: 'Success',
          description: 'Request for cancellation received.',
        });
        onConfirm();
      } else {
        toast({
          status: 'error',
          title: 'Error',
          description: err.message,
        });
        onAbort();
      }
    },
  });

  return (
    <Prompt
      alertMessage={
        needsApproval
          ? 'Picking for this shipment has started. Canceling at this point may incur additional charges.'
          : ''
      }
      title={needsApproval ? '' : `Cancel Shipment ${order?.soNumber}`}
      content={
        needsApproval ? (
          <Box>
            <Text as="h2" variant="pageTitle" mb={8}>
              Please contact us to cancel this shipment.
            </Text>
            <p>Contact your account manager or send us an email at:</p>
            <CopyableEmail emailAddress="customer-service@standvast.com" />
          </Box>
        ) : (
          <Text>Are you sure you want to cancel this shipment?</Text>
        )
      }
      buttons={[
        {
          label: 'Go Back',
          handler: onAbort,
        },
        {
          label: 'Cancel',
          handler: handleCancelOrder,
          isLoading,
          visible: !needsApproval,
        },
      ]}
    />
  );
}
