import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { SalesOrder } from 'types/salesOrders';
import { getDueDate } from 'utils/salesOrderUtils';
import { mapSalesOrderToDto } from './mappers';

export function useCreateSalesOrder() {
  const queryClient = useQueryClient();

  return useMutation<any, any, SalesOrder>({
    mutationFn: (order: SalesOrder) => {
      if (order.type === 'Parcel') {
        order.dueDate = getDueDate();
      }
      const mappedOrder = mapSalesOrderToDto(order);

      return axios.post(`/salesorder/${order.type.toLowerCase()}`, {
        request_transaction_id: '',
        ...(order.type === 'Freight'
          ? { order: mappedOrder }
          : { orders: [mappedOrder] }),
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['salesOrders'] });
    },
  });
}
