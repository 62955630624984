import {
  Button,
  ListItem,
  Text,
  Tooltip,
  UnorderedList,
  VStack,
} from '@chakra-ui/react';
import { Shimmer } from 'components/Shimmer';
import { usePurchaseOrderContact } from 'hooks/usePurchaseOrderContact';
import { PurchaseOrder } from 'types/purchaseOrders';
import { formatDate } from 'utils/dateUtils';

type Props = {
  order?: PurchaseOrder;
};

export function Appointments({ order }: Props) {
  const { url } = usePurchaseOrderContact(
    order?.poNumber ? [order?.poNumber] : []
  );

  return (
    <VStack spacing={2} align="flex-start">
      <Text variant="boldBlue" fontSize="xl">
        Appointments
      </Text>
      <UnorderedList fontWeight={300}>
        <ListItem>
          <Shimmer
            w="160px"
            h="20px"
            display="inline-block"
            verticalAlign="top"
            isLoaded={!!order}
          >
            <Tooltip label="Originally requested date when you created this shipping plan">
              <Text>
                Requested Delivery Date{' '}
                {formatDate(order?.requestedDate, 'MM/DD/YYYY', {
                  timeZone: 'UTC',
                })}
              </Text>
            </Tooltip>
          </Shimmer>
        </ListItem>
      </UnorderedList>
      <Button as="a" href={url} target="_blank" size="md">
        Contact Standvast Transportaion
      </Button>
    </VStack>
  );
}
