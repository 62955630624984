import {
  Box,
  Collapse,
  Flex,
  IconButton,
  SimpleGrid,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { SalesOrder, SalesOrderStatus } from 'types/salesOrders';
import { Address } from './components/ViewOrder/Address';
import { OrderInfoTable } from './components/ViewOrder/OrderInfoTable';
import { OrderLinesTable } from './components/ViewOrder/OrderLinesTable';
import { ErrorBoundary } from 'components/ErrorBoundary';
import SharedStyles from 'styles/shared.module.css';
import { Link } from 'react-router-dom';
import { OrderShippingBoxesTable } from './components/ViewOrder/OrderShippingBoxesTable';
import { InlineText } from 'components/InlineText';
import { StatusChangeList } from './components/ViewOrder/StatusChangeList';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { ButtonGroup } from 'components/ButtonGroup';
import { isCancellable } from 'utils/salesOrdersUtils';
import { useCreateReshipSalesOrder } from 'mutations/salesOrders/useCreateReshipSalesOrder';
import { useMemo } from 'react';
import { useAuth0Roles } from 'hooks/useAuth0Roles';
import {
  BAD_STATUS,
  INCOMPLETE_STATUS,
  PROCESSING_STATUS,
  SUCCESS_STATUS,
} from 'constants/order-statuses';
import { useCopyToClipboard } from 'hooks/useCopyToClipboard';
import { useSalesOrderLabelDetails } from 'queries/salesOrders/useSalesOrderLabelDetails';
import { LabelDetailsTable } from './components/ViewOrder/LabelDetailsTable';
import { useToast } from 'hooks/useToast';

type Props = {
  order?: SalesOrder;
  parentOrder?: SalesOrder;
  onEdit: () => void;
  onReRoute: () => void;
  onCancel: () => void;
};

export function OrderDetails({
  order,
  parentOrder,
  onEdit,
  onReRoute,
  onCancel,
}: Props) {
  const isAdmin = useAuth0Roles();
  const toast = useToast();
  const { shipFrom, shipTo } = order ?? {};
  const { isOpen: isVisibleShipTo, onToggle: onToggleVisibleShipTo } =
    useDisclosure();
  const { order: newRSOrder, create: createReshipOrder } =
    useCreateReshipSalesOrder(order);

  const { copy } = useCopyToClipboard(
    Object.values(order?.shipTo ?? {}).join('\n'),
    {
      toastOptions: {
        status: 'info',
        description: 'Ship to address copied',
      },
    }
  );

  const { data: labelInfo } = useSalesOrderLabelDetails(order?.soNumber!, {
    enabled: order?.type === 'Freight',
  });

  const allowRerouteOrder = useMemo(() => {
    return (
      (order?.status === SalesOrderStatus.Received ||
        order?.status === SalesOrderStatus.ReceivedUpdated) &&
      isAdmin
    );
  }, [order, isAdmin]);

  const currentStatus = order?.statusChangesHistory?.at(-1);

  const EyeIcon = isVisibleShipTo ? VisibilityOffIcon : VisibilityIcon;

  const latestStatusColor = useMemo(() => {
    const status = (currentStatus?.status || '') as SalesOrderStatus;

    if (PROCESSING_STATUS.includes(status)) return 'gray';
    else if (INCOMPLETE_STATUS.includes(status)) return 'secondaryOrange';
    else if (BAD_STATUS.includes(status)) return 'red';
    else if (SUCCESS_STATUS.includes(status)) return 'green';
    return 'gray';
  }, [currentStatus]);

  return (
    <>
      <ErrorBoundary>
        <Text mb={6} as="h2" variant="pageTitle">
          Shipment #{order?.soNumber}
        </Text>
        {order?.storefront?.displayName ? (
          <Box lineHeight={['shorter', null, 'base']}>
            <InlineText variant="boldBlue" fontSize="xl" mr={1}>
              Storefront:
            </InlineText>
            <InlineText fontSize="xl">
              {order?.storefront?.displayName}
            </InlineText>
          </Box>
        ) : null}
        <Flex
          justifyContent="space-between"
          flexWrap={['wrap', null, 'nowrap']}
        >
          {parentOrder ? (
            <Flex alignItems="center" gap={1} flexShrink={0}>
              <Text variant="boldBlue" fontSize="xl">
                Parent Shipment #:
              </Text>
              <Link
                aria-label={`Parent Shipment ${parentOrder.soNumber}`}
                to={`/orders/${parentOrder.soNumber}`}
              >
                <Text variant="link" textDecoration="underline" fontSize="lg">
                  {parentOrder.soNumber}
                </Text>
              </Link>
            </Flex>
          ) : null}
        </Flex>
      </ErrorBoundary>
      <SimpleGrid
        columns={[1, null, 3]}
        mt={5}
        mb={[5, null, 3]}
        spacing={[3, null, 2]}
      >
        <StatusChangeList
          history={order?.statusChangesHistory}
          latestStatusColor={latestStatusColor}
        />

        <Box>
          <Text variant="boldBlue" fontSize="xl">
            Ship From
          </Text>
          <ErrorBoundary>
            <Address address={shipFrom} />
          </ErrorBoundary>
        </Box>

        <Box>
          <ErrorBoundary>
            <Text
              variant="boldBlue"
              fontSize="xl"
              display="flex"
              alignItems="center"
            >
              Ship To
              <IconButton
                ml={0.5}
                aria-label="Toggle Visible Ship To"
                size="sm"
                variant="ghost"
                color="primaryBlue.500"
                icon={<EyeIcon fontSize="small" />}
                onClick={onToggleVisibleShipTo}
              />
              <IconButton
                aria-label="Copy Ship To"
                size="sm"
                variant="ghost"
                color="primaryBlue.500"
                icon={<ContentCopyIcon fontSize="small" />}
                onClick={copy}
              />
            </Text>
            <Collapse in={isVisibleShipTo} animateOpacity unmountOnExit>
              <Address address={shipTo} />
            </Collapse>
          </ErrorBoundary>
        </Box>
      </SimpleGrid>

      <Box className={SharedStyles['horizontal-scroll']} overflow="auto">
        <ErrorBoundary>
          <OrderInfoTable order={order} />
        </ErrorBoundary>
        <ErrorBoundary>
          <OrderLinesTable order={order} />
        </ErrorBoundary>
        {order?.shippingBoxesDetail && order?.shippingBoxesDetail.length > 0 ? (
          <ErrorBoundary>
            <OrderShippingBoxesTable data={order!.shippingBoxesDetail} />
          </ErrorBoundary>
        ) : null}
        {labelInfo?.labelDetails?.length ? (
          <ErrorBoundary>
            <LabelDetailsTable
              soNumber={order?.soNumber!}
              data={labelInfo?.labelDetails}
            />
          </ErrorBoundary>
        ) : null}
      </Box>
      <ButtonGroup
        wrapperProps={{
          mt: 8,
          display: 'grid',
          columnGap: 8,
          rowGap: 4,
          gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))',
        }}
        commonButtonWrapperProps={{ mr: 0, colSpan: 1 }}
        commonButtonProps={{ width: '100%' }}
        buttons={[
          {
            label: 'Modify',
            handler: () => {
              onEdit();
            },
            visible: ['Received', 'Received-Updated'].includes(
              order?.status ?? ''
            ),
            isDisabled:
              !order || (order.type === 'Parcel' && !order.carrierAccountUUID),
            buttonProps: {
              'aria-label': 'Modify',
            },
          },
          {
            label: 'Reroute',
            visible: allowRerouteOrder,
            handler: onReRoute,
          },
          {
            label: 'Cancel',
            handler: onCancel,
            disabledStateHandler: () =>
              toast({
                status: 'error',
                title: 'Error',
                description:
                  'Cannot cancel at this time as labelling is complete.',
              }),
            isDisabled: !order || !isCancellable(order),
            buttonProps: {
              'aria-label': 'Cancel',
            },
          },
          {
            label: 'Reship',
            tooltip:
              'Duplicate this shipment. Does not modify the existing shipment.',
            handler: createReshipOrder,
            buttonProps: {
              'aria-label': 'Reship',
              isDisabled: !newRSOrder,
            },
          },
        ]}
      />
    </>
  );
}
