import { Box, Text, useSteps } from '@chakra-ui/react';
import { PreviewPurchaseOrders } from 'features/upload-purchase-orders/PreviewPurchaseOrders';
import { ReviewPurchaseOrders } from 'features/upload-purchase-orders/ReviewPurchaseOrders';
import { UploadPurchaseOrders } from 'features/upload-purchase-orders/UploadPurchaseOrders';
import { useUploadPurchaseOrders } from 'mutations/purchaseOrders/useUploadPurchaseOrders';
import { useState } from 'react';
import { PurchaseOrderPreview } from 'types/purchaseOrders';

export function UploadPurchaseOrdersPage() {
  const [file, setFile] = useState<File>();
  const [purchaseOrders, setPurchaseOrders] = useState<PurchaseOrderPreview[]>(
    []
  );
  const { activeStep, setActiveStep, goToNext, goToPrevious } = useSteps({
    index: 1,
    count: 3,
  });
  const { mutate, error, isLoading } = useUploadPurchaseOrders();

  const uploadChange = (file: File, data: PurchaseOrderPreview[]) => {
    setFile(file);
    setPurchaseOrders(data);
    goToNext();
  };

  const uploadShippingPlans = () =>
    mutate(
      { file: file!, data: purchaseOrders },
      {
        onSettled: () => goToNext(),
      }
    );

  return (
    <Box>
      <Text as="h2" variant="pageTitle" mb={3}>
        Upload Shipping Plans
      </Text>
      {activeStep === 1 && <UploadPurchaseOrders onChange={uploadChange} />}
      {activeStep === 2 && (
        <PreviewPurchaseOrders
          isLoading={isLoading}
          data={purchaseOrders}
          onBack={goToPrevious}
          onConfirm={uploadShippingPlans}
        />
      )}
      {activeStep === 3 && (
        <ReviewPurchaseOrders
          data={purchaseOrders}
          error={error}
          onBack={() => setActiveStep(1)}
        />
      )}
    </Box>
  );
}
