import { PurchaseOrderUploadError } from 'types/purchaseOrders';
import {
  Box,
  Button,
  ListItem,
  Tag,
  Text,
  UnorderedList,
} from '@chakra-ui/react';
import { PaginatedTable } from 'components/PaginatedTable';
import { useLayoutPaginatedTable } from 'hooks/useLayoutPaginatedTable';
import { useMemo } from 'react';
import { Column } from 'react-table';
import { QueryState } from 'types/queryState';
import _omit from 'lodash-es/omit';

export type Props = {
  error: PurchaseOrderUploadError[];
  onBack: () => void;
};

export function ErrorUploadPurchaseOrders({ error, onBack }: Props) {
  const { getColumnWidth } = useLayoutPaginatedTable(800);

  const getPoNumbers = (error: PurchaseOrderUploadError) => {
    return Object.values(_omit(error, ['errors'])).join(', ');
  };

  const columns = useMemo(() => {
    return [
      {
        Header: 'Row',
        accessor: 'poNumber',
        width: getColumnWidth(40, 400),
        Cell: ({ row }) => <>{getPoNumbers(row.original)}</>,
      },
      {
        Header: 'Error Message',
        accessor: 'errors',
        width: getColumnWidth(60, 400),
        Cell: ({ value }) => (
          <UnorderedList listStyleType="none" m={0}>
            {value?.map((err) => (
              <ListItem key={err.message}>{err.message}</ListItem>
            ))}
          </UnorderedList>
        ),
      },
    ] as Column<PurchaseOrderUploadError>[];
  }, [getColumnWidth]);

  return (
    <>
      <Text
        fontWeight={500}
        display="flex"
        alignItems="center"
        flexWrap="wrap"
        gap={2}
      >
        <Tag
          as="span"
          size="lg"
          fontSize="lg"
          variant="solid"
          colorScheme="red"
        >
          Errors Found
        </Tag>
        Correct the file and reupload
      </Text>
      <Box maxW="100%" overflow="auto">
        <PaginatedTable
          clientSide
          tableName="error_upload_po_table"
          columns={columns}
          queryState={
            {
              data: error,
              isLoading: false,
            } as QueryState<PurchaseOrderUploadError>
          }
          isResizable
        />
      </Box>
      <Button size="md" colorScheme="primaryBlue" onClick={onBack}>
        Back
      </Button>
    </>
  );
}
