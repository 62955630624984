import { UNIT_OF_MEASURE_EACHES } from 'constants/unitOfMeasure';
import {
  DtoFieldError,
  DtoFieldErrorSegment,
} from 'hooks/useCrudActionUserFeedback';
import { AddressInfo, AddressInfoDto } from 'types/address';
import {
  SalesOrder,
  ParcelSalesOrderDetailDto,
  FreightSalesOrderDetailDto,
  SalesOrderLineBase,
  ParcelSalesOrderLine,
  FreightSalesOrderLine,
  ParcelSalesOrderLineDto,
  FreightSalesOrderLineDto,
  SalesOrderLineBaseDto,
  SalesOrderDetailDto,
  FreightSalesOrder,
  ParcelSalesOrder,
  CancelSalesOrderResult,
  CancelSalesOrderErrorResultDto,
} from 'types/salesOrders';
import { Tenant } from 'types/tenant';
import { formatDate } from 'utils/dateUtils';

function mapAddressToDto(address: Partial<AddressInfo>): AddressInfoDto {
  return {
    name: address?.name ?? '',
    address: address?.address ?? '',
    address_2: address?.address2 ?? '',
    city: address?.city ?? '',
    state: address?.state ?? '',
    zip_code: address?.zipCode ?? '',
    country: address?.country ?? '',
    email: address?.email ?? '',
    phone_number: address?.phoneNumber ?? '',
  };
}

function mapSalesOrderLineBaseToDto(
  line: SalesOrderLineBase
): SalesOrderLineBaseDto {
  return {
    line_number: line.lineNo,
    external_item_number: line.customerItemNumber,
    sku: line.sku,
    parent_bundle_sku: line.bundleSku || null,
    item_description: line.description,
    latest_ship_date: line.shipDate,
    uom: UNIT_OF_MEASURE_EACHES,
    order_qty: line.orderQty,
    picked_qty: line.pickedQty,
    ship_qty: line.shippedQty,
    short_qty: line.shortQty,
    tracking_numbers: line.trackingNumbers,
    unpicked_qty: line.unpickedQty,
    canceled_qty: line.canceledQty,
  };
}

function mapParcelSalesOrderLine(
  line: ParcelSalesOrderLine
): ParcelSalesOrderLineDto {
  return mapSalesOrderLineBaseToDto(line);
}

function mapFreightSalesOrderLine(
  line: FreightSalesOrderLine
): FreightSalesOrderLineDto {
  return {
    ...mapSalesOrderLineBaseToDto(line),
    customer_shipment_reference: line.customerShipmentReference,
    arn: line.arn,
    asn: line.asn,
  };
}

export function mapSalesOrderToDto(order: SalesOrder): SalesOrderDetailDto {
  return isFreightSalesOrder(order)
    ? mapFreightSalesOrderToDto(order)
    : mapParcelSalesOrderToDto(order);
}

export function mapParcelSalesOrderToDto(
  order: ParcelSalesOrder
): ParcelSalesOrderDetailDto {
  return {
    processing_type: 'Parcel',
    so_number: order.soNumber,
    po_number: order.poNumber,
    target_dc: order.warehouseID || 'SELECT_NODE',
    ship_via: order.shipVia,
    ship_from_address: mapAddressToDto(order.shipFrom),
    ship_to_address: mapAddressToDto(order.shipTo),
    customer_order_reference: order.customerNumber,
    instructions: order.instructions,
    created_date: order.createdDate,
    due_date: order.dueDate,
    earliest_due_date: order.earliestDueDate,
    latest_ship_date: order.latestDueDate,
    sales_order_lines: order.lines.map(mapParcelSalesOrderLine),
    latest_status: order.status,
    status_history:
      order.statusChangesHistory?.map((entry) => ({
        status: entry.status,
        status_date: entry.status_date,
        status_error_message: '',
      })) ?? [],
    carrier_scac_code: order.carrierScacCode || null,
    carrier_account_uuid: order.carrierAccountUUID,
    ship_option: order.shipOption || undefined,
    gift_message: order.giftMessage,
    tracking_numbers: [],
    storefront_name: order.storefrontName,
    order_source: order.orderSource,
  };
}

export function mapFreightSalesOrderToDto(
  order: FreightSalesOrder
): FreightSalesOrderDetailDto {
  const bolData = order.bolData;
  const billTo = bolData?.billingAddress;

  return {
    processing_type: 'Freight',
    so_number: order.soNumber,
    po_number: order.poNumber,
    target_dc: order.warehouseID || 'SELECT_NODE',
    ship_via: order.shipVia,
    ship_from_address: mapAddressToDto(order.shipFrom),
    ship_to_address: mapAddressToDto(order.shipTo),
    customer_order_reference: order.customerNumber,
    instructions: order.instructions,
    created_date: order.createdDate,
    due_date: order.dueDate,
    earliest_due_date: order.earliestDueDate,
    latest_ship_date: order.latestDueDate,
    sales_order_lines: order.lines.map(mapFreightSalesOrderLine),
    latest_status: order.status,
    status_history: order.statusChangesHistory?.map((entry) => ({
      status: entry.status,
      status_date: entry.status_date,
      status_error_message: '',
    })),
    carrier_scac_code: order.carrierScacCode || null,
    carrier_account_uuid: order.carrierAccountUUID || null,
    arn: order.arn ?? '',
    asn: order.asn ?? '',
    print_gtin14_label: !!order.printGtin14Label,
    print_upc_label: !!order.printUpcLabel,
    print_packing_labels: !!order.printPackingLabels,
    bol_data: bolData
      ? {
          bol_type: bolData.type ?? '',
          bol_number: bolData.bolNumber ?? '',
          ship_to_cid_number: bolData.shipToCidNumber ?? '',
          ship_to_location_number: bolData.shipToLocationNumber ?? '',
          fob: !!bolData.fob,
          bill_to_address:
            billTo && hasFilledBillingAddress(billTo)
              ? {
                  name: billTo.name,
                  address: billTo.address,
                  address_2: billTo.address2,
                  city: billTo.city,
                  state: billTo.state,
                  zip_code: billTo.zipCode,
                  country: billTo.country,
                  email: billTo.email,
                  phone: billTo.phoneNumber,
                }
              : null,
        }
      : null,
    gift_message: order.giftMessage,
    tracking_numbers: [],
    storefront_name: order.storefrontName,
    order_source: order.orderSource,
  };
}

export function hasFilledBillingAddress(address: AddressInfo) {
  if (!address) return false;

  return !!(
    address.address ||
    address.city ||
    address.country ||
    address.email ||
    address.name ||
    address.phoneNumber ||
    address.state ||
    address.zipCode
  );
}

export function mapFieldDtoErrorToRhfPath(error: DtoFieldError) {
  const path = [];
  const segments = error.loc?.slice(2) ?? [];

  for (let i = 0; i < segments.length; i++) {
    const segment = segments[i];
    let mappedSegment = null;

    if (i === 0) {
      mappedSegment = mapTopLevelDtoSegmentToRhfSegment(segment);
    } else if (segments[0] === 'sales_order_lines' && i === 1) {
      mappedSegment = segment;
    } else if (segments[0] === 'sales_order_lines' && i === 2) {
      mappedSegment = mapLinesDtoSegmentToRhfSegment(segment);
    } else if (segments[0] === 'ship_to_address' && i === 1) {
      mappedSegment = mapShipToDtoSegmentToRhfSegment(segment);
    }

    path.push(mappedSegment);
  }

  return path.length === 0 || path.some((s) => s === null)
    ? null
    : path.join('.');
}

function mapTopLevelDtoSegmentToRhfSegment(segment: DtoFieldErrorSegment) {
  return (
    {
      sales_order_lines: 'lines',
      ship_to_address: 'shipTo',
      so_number: 'soNumber',
      carrier_account_uuid: 'carrierAccountUUID',
      carrier_scac_code: 'carrierScacCode',
      ship_via: 'shipVia',
      po_number: 'poNumber',
    }[segment] ?? null
  );
}

function mapShipToDtoSegmentToRhfSegment(segment: DtoFieldErrorSegment) {
  return (
    {
      name: 'name',
      address: 'address',
      city: 'city',
      state: 'state',
      zip_code: 'zipCode',
      country: 'country',
      email: 'email',
      phone_number: 'phoneNumber',
    }[segment] ?? null
  );
}

function mapLinesDtoSegmentToRhfSegment(segment: DtoFieldErrorSegment) {
  return (
    {
      sku: 'sku',
      external_item_number: 'customerItemNumber',
      order_qty: 'orderQty',
    }[segment] ?? null
  );
}

function isFreightSalesOrder(order: SalesOrder): order is FreightSalesOrder {
  return (order as FreightSalesOrder).type === 'Freight';
}

export function mapDtoToCancelSalesOrderResult(
  soNumber: string,
  dto: CancelSalesOrderErrorResultDto | undefined
): CancelSalesOrderResult {
  return {
    soNumber,
    orderStatus: dto?.order_status,
    messages: dto?.error,
  };
}

export function mapSalesOrderToRerouteReport(
  order: SalesOrder,
  {
    warehouse,
    tenant,
  }: {
    warehouse: string;
    tenant: Tenant;
  }
) {
  const parcelOrderData = mapParcelSalesOrderToRerouteReport(
    !isFreightSalesOrder(order) ? order : undefined
  );
  const freightOrderData = mapFreightSalesOrderToRerouteReport(
    isFreightSalesOrder(order) ? order : undefined
  );
  return order.lines.map((item) => ({
    tenant_code: tenant.code,
    tenant_uuid: tenant.uuid,
    target_dc: warehouse,
    processing_type: order.type,
    so_number: order.soNumber,
    po_number: order.poNumber,
    ship_via: order.shipVia,
    carrier_account_uuid: order.carrierAccountUUID,
    ship_to_name: order.shipTo.name,
    ship_to_address1: order.shipTo.address,
    ship_to_address2: order.shipTo.address2,
    ship_to_city: order.shipTo.city,
    ship_to_state: order.shipTo.state,
    ship_to_zip: order.shipTo.zipCode,
    ship_to_country: order.shipTo.country,
    contact_phone_number: order.shipTo.phoneNumber,
    contact_email: order.shipTo.email,
    due_date: formatDate(order.dueDate),
    earliest_due_date: formatDate(order.earliestDueDate),
    customer_order_reference: order.customerNumber,
    instructions: order.instructions,
    so_line_number: item.lineNo,
    sku: item.sku,
    external_item_number: item.customerItemNumber,
    order_qty: item.orderQty,
    uom: item.unitOfMeasure,
    carrier_scac_code: order.carrierScacCode,
    ...parcelOrderData,
    ...freightOrderData,
  }));
}

export function mapParcelSalesOrderToRerouteReport(
  order: ParcelSalesOrder | undefined
) {
  return {
    ship_option: order?.shipOption,
  };
}

export function mapFreightSalesOrderToRerouteReport(
  order: FreightSalesOrder | undefined
) {
  return {
    arn: order?.arn ?? '',
    asn: order?.asn ?? '',
    print_shipping_label: order?.printPackingLabels ?? '',
    print_upc_label: order?.printUpcLabel ?? '',
    print_gtin14_label: order?.printGtin14Label ?? '',
    bol_type: order?.bolData?.type ?? '',
    bol_number: order?.bolData?.bolNumber ?? '',
    ship_to_cid_number: order?.bolData?.shipToCidNumber ?? '',
    ship_to_location_number: order?.bolData?.shipToLocationNumber ?? '',
    fob: order?.bolData?.fob ?? '',
    bill_to_name: order?.bolData?.billingAddress?.name ?? '',
    bill_to_address1: order?.bolData?.billingAddress?.address ?? '',
    bill_to_address2: order?.bolData?.billingAddress?.address2 ?? '',
    bill_to_city: order?.bolData?.billingAddress?.city ?? '',
    bill_to_state: order?.bolData?.billingAddress?.state ?? '',
    bill_to_zip: order?.bolData?.billingAddress?.zipCode ?? '',
    bill_to_country: order?.bolData?.billingAddress?.country ?? '',
    bill_to_phone: order?.bolData?.billingAddress?.phoneNumber ?? '',
    bill_to_email: order?.bolData?.billingAddress?.email ?? '',
  };
}
