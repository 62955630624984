import { Box, Button, GridItem, SimpleGrid, Text } from '@chakra-ui/react';
import { ChangeEventHandler, useMemo } from 'react';
import ClearIcon from '@mui/icons-material/Clear';
import { useFormContext } from 'react-hook-form';
import { PurchaseOrder } from 'types/purchaseOrders';
import { AddBoxButton } from 'components/AddBoxButton';
import { FormInput } from 'components/FormInput';
import { FormSelect } from 'components/FormSelect';
import { FormDatePicker } from 'components/FormDatePicker';
import { useWarehouses } from 'queries/warehouses/useWarehouses';
import { useContainerNumbers } from 'hooks/useContainerNumbers';
import { addBusinessDays } from 'date-fns';
import { getDeliveryWindow } from 'utils/warehouseUtils';
import { PURCHASE_ORDER_DELIVERY_DAYS } from 'constants/warehouseTimeZones';

export function SchedulePurchaseOrderForm() {
  const {
    register,
    control,
    getValues,
    setValue,
    formState: { errors },
    watch,
  } = useFormContext<PurchaseOrder>();
  const {
    containerNumberFields,
    appendContainerNumber,
    removeContainerNumber,
  } = useContainerNumbers(control);

  const warehouseID = watch('warehouseID');

  const minRequestedDate = useMemo(
    () =>
      addBusinessDays(
        Date.now(),
        getDeliveryWindow(warehouseID ?? '', PURCHASE_ORDER_DELIVERY_DAYS)
      ),
    [warehouseID]
  );

  const { data = [] } = useWarehouses();

  const warehouses = useMemo(
    () =>
      data.map(({ code, address, displayText }) => ({
        value: code,
        label: displayText,
        address,
      })),
    [data]
  );

  const handleWarehouseChange: ChangeEventHandler<HTMLSelectElement> = (e) => {
    const code = e.target.value;
    const address = data.find((warehouse) => warehouse.code === code)?.address;
    setValue(
      'deliveryAddress',
      address
        ? {
            ...address,
            name: '',
            email: '',
            phoneNumber: '',
            zipCode: address.zip,
            country: 'US',
          }
        : undefined
    );
  };

  const handleAddContainerNumberClick = () =>
    appendContainerNumber({
      containerNumber: '',
    });

  const handleRemoveContainerNumberclick = (idx: number) =>
    removeContainerNumber(idx);

  return (
    <SimpleGrid columns={{ sm: 1, lg: 3 }} gap={8} mt={{ sm: 5, lg: 0 }}>
      <GridItem>
        <FormInput
          label="Shipping Plan #"
          isRequired
          placeholder="Please enter a Shipping Plan #"
          {...register('poNumber')}
        />
      </GridItem>
      <GridItem>
        <FormSelect
          label="Fulfillment Center"
          isRequired
          placeholder="Please select a location"
          data-testid="warehouse"
          {...register('warehouseID', { onChange: handleWarehouseChange })}
          options={warehouses}
        />
      </GridItem>
      <GridItem>
        <FormDatePicker
          minDate={minRequestedDate}
          label="Requested Delivery Date"
          isRequired
          name="requestedDate"
          defaultValue={getValues().requestedDate}
          onChange={(value: string) =>
            setValue('requestedDate', value, { shouldDirty: true })
          }
          placeholder="Please select a date"
          aria-label="Filter"
          variant="flushed"
          error={errors.requestedDate?.message}
          isClearable
        />
      </GridItem>
      <GridItem>
        <Text variant="boldBlue" fontSize="xl" mb={2}>
          Container Numbers
        </Text>
        {containerNumberFields.map((field, i) => (
          <Box
            display="flex"
            gap={4}
            key={field.id}
            mb={4}
            data-testid={`container-numbers.${i}`}
          >
            <FormInput {...register(`containerNumbers.${i}.containerNumber`)} />
            <Button
              onClick={() => handleRemoveContainerNumberclick(i)}
              fontSize="md"
              variant="redSquareButton"
              title="Remove Container Number"
            >
              <ClearIcon fontSize="inherit" />
            </Button>
          </Box>
        ))}
        <AddBoxButton
          onClick={handleAddContainerNumberClick}
          title="Add Container"
        >
          Add Container
        </AddBoxButton>
      </GridItem>
    </SimpleGrid>
  );
}
