import { Box, Button, Text } from '@chakra-ui/react';
import { UploadButton } from 'components/UploadButton';
import { useItemMastersUpload } from 'mutations/itemMasters/useItemMastersUpload';
import { useState } from 'react';
import {
  ItemMastersUploadMode,
  ItemMasterUploadError,
  ItemMasterUploadResponse,
} from 'types/itemMaster';
import { FileType } from 'utils/fileTypes';
import { useToast } from 'hooks/useToast';

type UploadItemMastersProps = {
  title: string;
  description: string;
  mode: ItemMastersUploadMode;
  onUploadSettled?: (
    data?: ItemMasterUploadResponse,
    error?: ItemMasterUploadError
  ) => void;
  onFileChanged?: (file?: File) => void;
};

export function UploadItemMasters({
  mode,
  title,
  description,
  onUploadSettled,
  onFileChanged,
}: UploadItemMastersProps) {
  const toast = useToast();
  const [file, setFile] = useState<File>();
  const { mutateAsync, isLoading } = useItemMastersUpload({
    onSettled(data, error) {
      onUploadSettled?.(data, error);
    },
  });

  async function fileChange(file?: File) {
    if (file && file.type !== FileType.Csv) {
      toast({
        status: 'error',
        title: 'Error',
        description: 'Invalid file format',
      });
    } else {
      setFile(file);
      onFileChanged?.(file);
    }
  }

  function upload() {
    if (!file) return;
    mutateAsync({ mode, file });
  }

  return (
    <Box mb={4}>
      <Text as="h3" variant="pageHeading" mb={2}>
        {title}
      </Text>
      <Text fontWeight={500} mb={3}>
        {description}
      </Text>
      <Box
        display="flex"
        flexDir="column"
        alignItems="start"
        justifyContent="center"
      >
        <Box
          display="flex"
          flexDir={['column', 'row']}
          alignItems="center"
          mb={4}
        >
          <Text fontWeight={500} mr={[0, 1, 3]} mb={[3, 0]}>
            Select file to upload:
          </Text>

          <UploadButton
            data-testid="itemMastersUpload"
            label="Choose file"
            accept=".csv"
            onChange={fileChange}
          />
        </Box>
        <Button
          isLoading={isLoading}
          isDisabled={!Boolean(file)}
          size="md"
          variant="cta"
          onClick={upload}
        >
          Upload now
        </Button>
      </Box>
    </Box>
  );
}
