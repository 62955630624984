import {
  Button,
  Flex,
  Radio,
  RadioGroup,
  Stack,
  Text,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { UploadButton } from 'components/UploadButton';
import { useWarehouses } from 'queries/warehouses/useWarehouses';
import { useEffect, useState } from 'react';
import { PurchaseOrderPreview } from 'types/purchaseOrders';
import { readFileAsText } from 'utils/fileUtils';
import { parse } from 'csv-parse/browser/esm/sync';
import { mapRowsToPurchaseOrdersPreview } from 'mutations/purchaseOrders/mappers';
import {
  SHIPPING_PLAN_HELP_AND_EXAMPLE,
  UPLOAD_PO_TEMPLATE_URL,
} from 'constants/templateLinks';

const ROW_LIMIT = 100;

type Props = {
  onChange: (file: File, data: PurchaseOrderPreview[]) => void;
};

export function UploadPurchaseOrders({ onChange }: Props) {
  const toast = useToast();
  const [warehouse, setWarehouse] = useState('');
  const { data: warehouses } = useWarehouses();

  const handleUpload = async (file?: File) => {
    if (!file) return;
    try {
      const data = parse(await readFileAsText(file), {
        columns: true,
        trim: true,
        relax_column_count: true,
        skip_empty_lines: true,
        from_line: 2,
        cast: (value) => (value === '' ? undefined : value),
      });

      let errorMessage = '';
      if (!Array.isArray(data) || !data.length) {
        errorMessage = 'No data found in file';
      } else if (data.length > ROW_LIMIT) {
        errorMessage = `Limit of ${ROW_LIMIT} rows per file`;
      }
      if (errorMessage) {
        toast({
          status: 'error',
          title: 'Error',
          description: errorMessage,
        });
        return;
      }
      onChange(
        file,
        mapRowsToPurchaseOrdersPreview(
          data,
          warehouses!.find((w) => w.code === warehouse)!
        )
      );
    } catch (err: any) {
      toast({
        status: 'error',
        title: 'Invalid CSV',
        description: err.message,
      });
    }
  };

  useEffect(() => {
    if (warehouses?.length && !warehouse) {
      setWarehouse(warehouses[0].code);
    }
  }, [warehouses, warehouse]);

  return (
    <VStack align="start" spacing={3}>
      <Text as="h3" variant="pageHeading" my={1}>
        1. Download Template
      </Text>
      <Text fontWeight={500}>
        Download the template to see what fields are necessary.
      </Text>
      <Button as="a" size="md" download href={UPLOAD_PO_TEMPLATE_URL}>
        Download Template
      </Button>
      <Text fontWeight={500}>
        Complete the template according to your needs.{' '}
        <Text as="b" fontWeight={700}>
          Limit of {ROW_LIMIT} rows per file.
        </Text>
        <br />
        Check the item master to make sure your SKUs are in the system.
      </Text>
      <Flex gap={3}>
        <Button
          as="a"
          size="md"
          target="_blank"
          href={SHIPPING_PLAN_HELP_AND_EXAMPLE}
        >
          View Help and Examples
        </Button>
        <Button as="a" size="md" target="_blank" href="/item-masters">
          View Item Master
        </Button>
      </Flex>
      <Text as="h3" variant="pageHeading" my={1}>
        2. Upload File
      </Text>
      <Text fontWeight={500}>
        Specify which fulfillment center to send inventory. If sending to
        multiple fulfillment centers, then create a new file for each location.
      </Text>
      <RadioGroup value={warehouse} onChange={(code) => setWarehouse(code)}>
        <Stack spacing={5} direction="row" flexWrap="wrap">
          {warehouses?.map((item) => (
            <Radio key={item.code} value={item.code}>
              {item.displayText}
            </Radio>
          ))}
        </Stack>
      </RadioGroup>
      <Text fontWeight={500}>
        Upload the file. Your input will be checked and you can preview before
        executing.
      </Text>
      <UploadButton
        slim
        data-testid="shippingPlansUpload"
        accept=".csv"
        label="Upload"
        buttonProps={{
          size: 'md',
          variant: 'solid',
          colorScheme: 'primaryBlue',
          isDisabled: !warehouse?.length,
        }}
        onChange={handleUpload}
      />
    </VStack>
  );
}
