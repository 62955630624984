/* istanbul ignore file */
import { GridItem, SimpleGrid } from '@chakra-ui/react';
import { ShipViaSelect } from 'features/create-order/ShipViaSelect';
import ShipOptionSelect from 'features/create-order/ShipOptionSelect';
import { RATE_SHOP_OPTION } from 'queries/shipVias/useShipVias';
import { useFormContext } from 'react-hook-form';
import { GiftMessageForm } from 'features/create-order/GiftMessageForm';
import CarrierAccountSelect from 'features/create-order/CarrierAccountSelect';
import { CreateNetworkOrder } from 'types/networkOrder';

type Props = {
  startTabIndex: number;
};

export const CreateNetworkOrderForm: React.FC<Props> = ({ startTabIndex }) => {
  const { watch } = useFormContext<CreateNetworkOrder>();

  const shipViaValue = watch('shipVia');

  return (
    <SimpleGrid columns={[1, 2]} columnGap={8}>
      <GridItem colSpan={[1, shipViaValue === RATE_SHOP_OPTION ? 1 : 2]}>
        <ShipViaSelect tabIndex={startTabIndex + 1} />
      </GridItem>
      {shipViaValue === RATE_SHOP_OPTION ? (
        <ShipOptionSelect tabIndex={startTabIndex + 2} />
      ) : null}
      <GridItem>
        <CarrierAccountSelect tabIndex={startTabIndex + 3} isRequired={false} />
      </GridItem>
      <GridItem colSpan={2} mt={8}>
        <GiftMessageForm tabIndex={startTabIndex + 4} />
      </GridItem>
    </SimpleGrid>
  );
};
