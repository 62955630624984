import { AddressInfo } from './address';
import { SalesOrderStatus } from './salesOrders';

export type NetworkOrder = {
  noName: string;
  status: string;
  shipVia: string;
  shipmentCount: number;
  createdAt: string;
  statusUpdatedAt: string;
};

export type NetworkOrderDto = {
  no_name: string;
  status: string;
  ship_via: string;
  shipment_count: number;
  created_at: string;
  status_updated_at: string;
};

export type PaginatedOrdersResponseDto = {
  count: number;
  next: string;
  previous: string;
  results: NetworkOrderDto[];
};

export enum NetworkOrderStatus {
  BackOrder = 'BACKORDER',
  Cancelled = 'CANCELLED',
  Complete = 'COMPLETE',
  Error = 'ERROR',
  Partial = 'PARTIAL',
  Processing = 'PROCESSING',
  Received = 'RECEIVED',
}

export type NetworkOrderLineItemBaseDto = {
  line_number: number;
  sku: string;
  external_item_number?: string;
};

export type NetworkOrderLineItemDetails = {
  lineNumber: number;
  sku: string;
  qtyPerParent: number;
  qtyRequested: number;
  qtyFulfilled: number;
};

export type NetworkOrderLineItemDto = NetworkOrderLineItemBaseDto & {
  qty_requested: number;
  qty_fulfilled: number;
  child_skus: [
    {
      line_number: number;
      sku: string;
      qty_per_parent: number;
      qty_requested: number;
      qty_fulfilled: number;
    }
  ];
};

export type CreateNetworkOrderLineItemDto = NetworkOrderLineItemBaseDto & {
  qty: number;
};

export type NetworkOrderLineItemBase = {
  lineNo: string;
  sku: string;
  customerItemNumber?: string;
};

export type NetworkOrderLineItem = NetworkOrderLineItemBase & {
  qtyRequested: number;
  qtyFulfilled: number;
  childSkus?: NetworkOrderLineItemDetails[];
};

export type CreateNetworkOrderLineItem = NetworkOrderLineItemBase & {
  orderQty: number;
};

export type NetworkOrderShipmentDto = {
  shipment_number: string;
  po_number: string;
  target_dc: string;
  status: SalesOrderStatus;
  created_at: string;
  line_items: NetworkOrderShipmentLineItemDto[];
};

export type NetworkOrderShipmentLineItemDto = {
  line_number: number;
  external_item_number: string;
  sku: string;
  order_qty: number;
  ship_qty: number;
  short_qty: number;
};

export type NetworkOrderShipment = {
  shipmentNumber: string;
  poNumber: string;
  targetDc: string;
  status: SalesOrderStatus;
  createdAt: string;
  lineItems: NetworkOrderShipmentLineItem[];
};

export type NetworkOrderShipmentLineItem = {
  lineNumber: number;
  externalItemNumber: string;
  sku: string;
  orderQty: number;
  shipQty: number;
  shortQty: number;
};

export type NetworkOrderBaseDto = {
  name: string;
  po_number?: string;
  ship_via: string;
  ship_option?: string;
  target_dc: string;
  carrier_account_uuid: string;
  due_date: string;
  desired_delivery_date?: string;
  earliest_due_date?: string;
  instructions?: string;
  gift_message?: string;
  order_source?: string;
  customer_order_reference?: string;
  address: {
    name: string;
    city: string;
    state: string;
    postal_code: string;
    country: string;
    phone_number: string;
    address_1: string;
    address_2?: string;
    address_3?: string;
    email?: string;
  };
};

export type NetworkOrderDetailsDto = NetworkOrderBaseDto & {
  status: NetworkOrderStatus;
  status_updated_at: string;
  created_at: string;
  error_type: string;
  error_msg: string;
  line_items: NetworkOrderLineItemDto[];
  shipments: NetworkOrderShipmentDto[];
};

export type CreateNetworkOrderDto = NetworkOrderBaseDto & {
  tenant_username?: string;
  request_transaction_id: string;
  line_items: CreateNetworkOrderLineItemDto[];
};

export type NetworkOrderBase = {
  name: string;
  poNumber?: string;
  shipVia: string;
  shipOption?: string;
  warehouseID: string;
  carrierAccountUUID: string;
  dueDate: string;
  desiredDeliveryDate?: string;
  earliestDueDate?: string;
  instructions?: string;
  giftMessage?: string;
  orderSource?: string;
  customerOrderReference?: string;
  shipTo: AddressInfo;
};

export type NetworkOrderDetails = NetworkOrderBase & {
  status: NetworkOrderStatus;
  statusUpdatedAt: string;
  createdAt: string;
  errorType: string;
  errorMsg: string;
  lines: NetworkOrderLineItem[];
  shipments: NetworkOrderShipment[];
};

export type CreateNetworkOrder = NetworkOrderBase & {
  tenantUsername?: string;
  requestTransactionId: string;
  lines: CreateNetworkOrderLineItem[];
};
