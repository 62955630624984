import { Box, BoxProps } from '@chakra-ui/react';
import { useRef, useState, useEffect } from 'react';

type Props = Required<Pick<BoxProps, 'w'>> &
  Partial<BoxProps> & {
    children: React.ReactNode;
  };

export function TextEllipsis({ children, w, ...props }: Props) {
  const [hasEllipsis, setHasEllipsis] = useState(false);
  const wrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const el = wrapperRef.current;
    const cell = el?.closest('td');
    const rowCells = Array.from(
      cell?.closest('tr')?.children ?? []
    ) as HTMLTableCellElement[];

    if (!cell || rowCells.length < 2) return;

    const contentEl = el?.children[0]! as HTMLDivElement;
    setHasEllipsis(contentEl.offsetWidth < contentEl.scrollWidth);
  }, [wrapperRef]);

  return (
    <Box
      position="relative"
      ref={wrapperRef}
      {...props}
      w={w}
      cursor={hasEllipsis ? 'pointer' : 'default'}
    >
      <Box textOverflow="ellipsis" overflow="hidden" whiteSpace="nowrap">
        {children}
      </Box>
    </Box>
  );
}
