import { isAfter, add } from 'date-fns';

/**
 * If creating a new Parcel Shipment or Order, set a default value for due_date when calling the API to create the SO as follows. Note, we made add more logic to this later:
 *
 * If it is before 2pm Eastern Time, set due_date to "today at 11:59pm ET"
 *
 * If it is after 2pm Eastern Time, set due_date to "tomorrow at 11:59 ET"
 */
export function getDueDate() {
  const now = new Date();
  const nowUTC = Date.UTC(
    now.getUTCFullYear(),
    now.getUTCMonth(),
    now.getUTCDate(),
    now.getUTCHours(),
    now.getUTCMinutes(),
    now.getUTCSeconds()
  );
  const sixPM = Date.UTC(
    now.getUTCFullYear(),
    now.getUTCMonth(),
    now.getUTCDate(),
    18,
    0,
    0
  );
  let dueDate = add(sixPM, { hours: 9, minutes: 59 });
  if (isAfter(nowUTC, sixPM)) {
    dueDate = add(dueDate, { days: 1 });
  }
  return dueDate.toISOString();
}
