import { SimpleGrid } from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';
import { FormInput } from 'components/FormInput';
import { isReshipOrder } from 'utils/salesOrdersUtils';
import { CreateNetworkOrder } from 'types/networkOrder';

type Props = {
  startTabIndex: number;
};

export const BasicInfoForm: React.FC<Props> = ({ startTabIndex }) => {
  const { register, getValues } = useFormContext<CreateNetworkOrder>();
  const draftOrder = getValues();

  return (
    <SimpleGrid columns={[1, 2]} columnGap={8}>
      <FormInput
        tabIndex={startTabIndex + 1}
        label="Order #"
        {...register('name')}
        isReadOnly={Boolean(isReshipOrder(draftOrder.name))}
        isRequired
        tooltip="The order number from your system."
      />
      <FormInput
        tabIndex={startTabIndex + 2}
        label="Shipping Plan #"
        {...register('poNumber')}
        tooltip="Optional. A secondary reference number for this order."
      />
    </SimpleGrid>
  );
};
