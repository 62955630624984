import { useToast as useChakraToast, UseToastOptions } from '@chakra-ui/react';
import { datadogRum } from '@datadog/browser-rum';

export const useToast = (options?: UseToastOptions) => {
  const chakraToast = useChakraToast(options);

  const toast = (options?: UseToastOptions) => {
    if (options?.status === 'error') {
      datadogRum.addError(new Error('Error Toast'), {
        title: options?.title?.toString(),
        description: options?.description?.toString(),
      });
    }
    return chakraToast(options);
  };

  toast.closeAll = chakraToast.closeAll;
  toast.close = chakraToast.close;
  toast.isActive = chakraToast.isActive;
  toast.promise = chakraToast.promise;
  toast.update = chakraToast.update;

  return toast;
};
