import { UseToastOptions } from '@chakra-ui/react';
import { useToast } from 'hooks/useToast';

type UseCopyToClipboardOptions = {
  toastOptions?: UseToastOptions;
};

export const useCopyToClipboard = (
  text: string,
  { toastOptions }: UseCopyToClipboardOptions
) => {
  const toast = useToast();

  const copy = () => {
    navigator.clipboard.writeText(text);
    if (toastOptions) toast(toastOptions);
  };

  return {
    copy,
  };
};
