import {
  Box,
  Button,
  ButtonProps,
  Text,
  useControllableState,
} from '@chakra-ui/react';
import { useRef } from 'react';

type UploadButtonProps = Omit<
  React.InputHTMLAttributes<HTMLInputElement>,
  'onChange' | 'type' | 'defaultValue'
> & {
  label: string;
  defaultValue?: File;
  slim?: boolean;
  buttonProps?: ButtonProps;
  onChange?: (file?: File) => void;
};

export function UploadButton({
  label,
  onChange,
  defaultValue,
  buttonProps,
  slim,
  ...props
}: UploadButtonProps) {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [file, setFile] = useControllableState<File | undefined>({
    defaultValue,
    onChange,
  });

  const fileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event?.target?.files?.[0];
    setFile(file);
  };

  const upload = () => {
    setFile(undefined);
    fileInputRef.current!.value = '';
    fileInputRef.current!.click();
  };

  return (
    <Box display="flex" alignItems="center">
      <Button size="md" onClick={upload} flexShrink={0} {...buttonProps}>
        {label}
      </Button>
      {!slim && (
        <Text
          noOfLines={2}
          ml={2}
          fontWeight="500"
          wordBreak="break-word"
          title={file?.name ?? 'No file chosen'}
        >
          {file?.name ?? 'No file chosen'}
        </Text>
      )}
      <input
        {...props}
        ref={fileInputRef}
        type="file"
        style={{ display: 'none' }}
        onChange={fileChange}
      />
    </Box>
  );
}
