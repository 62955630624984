import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import { Box } from '@chakra-ui/react';
import { datadogRum } from '@datadog/browser-rum';
import { BigLoading } from 'components/BigLoading';
import { useSearchParams } from 'react-router-dom';
import { AccessDenied } from './AccessDenied';
import { useAuth0Roles } from 'hooks/useAuth0Roles';
import { useEffect, useMemo, useState } from 'react';
import { CONFIG } from 'utils/config';
import { useTenant } from 'queries/tenant/useTenant';

interface ProtectedRouteProps {
  component: React.ComponentType<any>;
  requireOneOfRoles?: Array<string>;
  allowTenants?: Array<string>;
}

export const ProtectedRoute = ({
  component,
  requireOneOfRoles,
  allowTenants,
}: ProtectedRouteProps) => {
  const [query] = useSearchParams();
  const invitation = query.get('invitation') ?? undefined;
  const organization = query.get('organization') ?? undefined;
  const error = query.get('error') ?? undefined;
  const hasOneOfRoles = useAuth0Roles(requireOneOfRoles);
  const { user } = useAuth0();
  const { data: tenant, isLoading: isLoadingTenant } = useTenant();
  const [isTenantAllowed, setIsTenantAllowed] = useState(false);

  useEffect(() => {
    if (CONFIG().featureToggles.enableDataDogRUM && user) {
      datadogRum.setUser({
        email: user.email,
        id: user.sub,
        name: user.name,
        nickname: user.nickname,
        org_id: user.org_id,
        organization_name: user.organization_name,
      });
    }
  }, [user]);

  useMemo(() => {
    if (allowTenants) {
      setIsTenantAllowed(allowTenants.includes(tenant?.code ?? ''));
    }
  }, [tenant, allowTenants]);

  const Component = withAuthenticationRequired(component, {
    onRedirecting: () => (
      <Box>
        <BigLoading />
      </Box>
    ),
    claimCheck: (claims) => {
      const claimsOrgId = claims?.org_id ?? undefined;
      // return false if the Auth0 organization ID in the URL doesn't match the claim to trigger login
      if (organization != null && organization !== claimsOrgId) {
        return false;
      }
      return true;
    },
    loginOptions: {
      organization,
      invitation,
    },
  });

  if (isLoadingTenant) {
    return <BigLoading />;
  }

  return error === 'access_denied' ||
    (!!requireOneOfRoles && !hasOneOfRoles) ||
    (!!allowTenants && !isTenantAllowed) ? (
    <AccessDenied />
  ) : (
    <Component />
  );
};
