import {
  Button,
  Link,
  Text,
  VStack,
  ButtonProps,
  LinkProps,
} from '@chakra-ui/react';
import { TemplateSelect } from 'features/template/TemplateSelect';
import { FormProvider, useForm } from 'react-hook-form';

const ButtonLink = ({
  href,
  isDisabled,
  children,
  ...props
}: ButtonProps & LinkProps) => {
  return isDisabled ? (
    <Button variant="outline" size="md" isDisabled={isDisabled} {...props}>
      {children}
    </Button>
  ) : (
    <Button
      as={Link}
      variant="outline"
      size="md"
      isExternal
      href={href}
      isDisabled={isDisabled}
      {...props}
    >
      {children}
    </Button>
  );
};

export const TemplatesPage = () => {
  const formContext = useForm({
    defaultValues: {
      template: '',
      instruction: '',
      submit: '',
    },
  });

  const { watch } = formContext;

  const templateUrl = watch('template');
  const instructionUrl = watch('instruction');
  const submitUrl = watch('submit');

  return (
    <FormProvider {...formContext}>
      <VStack spacing={4} align="start">
        <Text as="h2" variant="pageTitle">
          Templates
        </Text>
        <Text fontWeight={500}>
          Use these templates to simplify bulk actions into Standvast.
        </Text>
        <TemplateSelect />
        <VStack spacing={2.5} align="start">
          <Text as="h3" variant="pageHeading">
            (2) Complete the Template
          </Text>
          <Text>Copy the template and then complete offline.</Text>
          <ButtonLink href={templateUrl} isDisabled={!templateUrl}>
            Open Template
          </ButtonLink>
          <Text>
            Confused about a field? View our help docs for more details.
          </Text>
          <ButtonLink href={instructionUrl} isDisabled={!instructionUrl}>
            View Instructions
          </ButtonLink>
        </VStack>
        <VStack spacing={2.5} align="start">
          <Text as="h3" variant="pageHeading">
            (3) Send to Standvast
          </Text>
          <Text>Submit to the help center</Text>
          <ButtonLink
            variant="solid"
            colorScheme="primaryBlue"
            href={submitUrl}
            isDisabled={!submitUrl}
          >
            Submit
          </ButtonLink>
        </VStack>
      </VStack>
    </FormProvider>
  );
};
