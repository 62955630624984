import { Box } from '@chakra-ui/react';
import { FormDateRangePicker } from 'components/FormDateRangePicker';
import { useUploadMsaContext } from 'hooks/useUploadMsa';
import {
  areIntervalsOverlapping,
  differenceInDays,
  startOfDay,
  isEqual,
} from 'date-fns';
import { parseDate } from 'utils/dateUtils';
import { useToast } from 'hooks/useToast';

export function ConfirmEffectiveDate() {
  const toast = useToast({ duration: 10000 });
  const { effectiveDateRange, setEffectiveDateRange, msaDocuments } =
    useUploadMsaContext();

  const validateOverlapping = () => {
    const [currentStartDate, currentEndDate] = effectiveDateRange.map((date) =>
      startOfDay(parseDate(date)!)
    );
    return msaDocuments.some(({ effectiveStartDate, effectiveEndDate }) => {
      const docStartDate = startOfDay(parseDate(effectiveStartDate)!);
      const docEndDate = startOfDay(parseDate(effectiveEndDate)!);
      if (
        areIntervalsOverlapping(
          { start: docStartDate, end: docEndDate },
          { start: currentStartDate, end: currentEndDate }
        ) ||
        isEqual(docStartDate, currentEndDate) ||
        isEqual(currentStartDate, docEndDate)
      ) {
        toast({
          status: 'warning',
          title: 'Warning',
          description:
            'This Effective Date Range overlaps with that of another MSA. Please confirm that you have selected the right dates.',
        });
        return true;
      }
      return false;
    });
  };

  const validateGap = () => {
    const [currentStartDate, currentEndDate] = effectiveDateRange.map((date) =>
      startOfDay(parseDate(date)!)
    );
    const valid = msaDocuments.some(
      ({ effectiveStartDate, effectiveEndDate }) => {
        const docStartDate = startOfDay(parseDate(effectiveStartDate)!);
        const docEndDate = startOfDay(parseDate(effectiveEndDate)!);
        return (
          differenceInDays(currentStartDate, docEndDate) === 1 ||
          differenceInDays(currentEndDate, docStartDate) === -1
        );
      }
    );
    if (!valid) {
      toast({
        status: 'warning',
        title: 'Warning',
        description:
          'There is a gap between the Effective Start Date selected and your other MSA(s). Please review your Effective Start Date carefully before submitting',
      });
    }
  };

  const validateEffectiveDate = () => {
    if (
      msaDocuments.length &&
      effectiveDateRange.length === 2 &&
      effectiveDateRange.every(Boolean)
    ) {
      toast.closeAll();
      const isOverlapping = validateOverlapping();
      if (!isOverlapping) validateGap();
    }
  };

  return (
    <Box py={6}>
      <FormDateRangePicker
        showMonthDropdown
        showYearDropdown
        variant="flushed"
        popperProps={{ strategy: 'fixed' }}
        defaultValue={[
          parseDate(effectiveDateRange[0]) ?? '',
          parseDate(effectiveDateRange[1]) ?? '',
        ]}
        aria-label="Confirm Effective Date"
        placeholder="Please select a date range"
        onChange={(value) => setEffectiveDateRange(value)}
        onCalendarClose={validateEffectiveDate}
      />
    </Box>
  );
}
