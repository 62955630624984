import { FormSelect, FormSelectProps } from 'components/FormSelect';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

type Props = Omit<FormSelectProps, 'options'> & {};

const shipOptions = [
  { label: 'Standard', value: 'Standard' },
  { label: 'Three Day', value: 'ThreeDay' },
  { label: 'Two Day', value: 'TwoDay' },
  { label: 'Next Day', value: 'NextDay' },
];

const shipOptionKey = 'shipOption';

function ShipOptionSelect(props: Props) {
  const { register, setValue, getValues, watch } = useFormContext();

  useEffect(() => {
    if (!getValues().shipOption) {
      setValue('shipOption', shipOptions[0].value, {
        shouldValidate: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const country = watch('shipTo.country');

  return (
    <FormSelect
      isRequired
      isDisabled={Boolean(country && country !== 'US')}
      aria-label="Service Level"
      label={'Service Level'}
      {...props}
      {...register(shipOptionKey)}
      options={shipOptions}
      tooltip="Desired service level"
    />
  );
}

export default ShipOptionSelect;
