import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { CreateNetworkOrder } from 'types/networkOrder';
import { getDueDate } from 'utils/salesOrderUtils';
import { mapNetworkOrderToDto } from './mappers';

export function useCreateNetworkOrder() {
  const queryClient = useQueryClient();
  return useMutation<any, any, CreateNetworkOrder>({
    mutationFn: (order: CreateNetworkOrder) => {
      order.dueDate = getDueDate();
      order.requestTransactionId = crypto.randomUUID();
      return axios.post('/oms/network_order', mapNetworkOrderToDto(order));
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['networkOrders'] });
    },
  });
}
