import {
  NetworkOrder,
  NetworkOrderDto,
  NetworkOrderDetails,
  NetworkOrderDetailsDto,
  CreateNetworkOrder,
  CreateNetworkOrderLineItem,
} from 'types/networkOrder';

export function mapDtoToNetworkOrder(dto: NetworkOrderDto): NetworkOrder {
  return {
    noName: dto.no_name,
    status: dto.status,
    shipVia: dto.ship_via,
    shipmentCount: dto.shipment_count,
    createdAt: dto.created_at,
    statusUpdatedAt: dto.status_updated_at,
  };
}

export function mapDtoToNetworkOrderDetails(
  dto: NetworkOrderDetailsDto
): NetworkOrderDetails {
  return {
    name: dto.name,
    poNumber: dto.po_number,
    shipVia: dto.ship_via,
    shipOption: dto.ship_option,
    warehouseID: dto.target_dc,
    carrierAccountUUID: dto.carrier_account_uuid,
    dueDate: dto.due_date,
    desiredDeliveryDate: dto.desired_delivery_date,
    earliestDueDate: dto.earliest_due_date,
    instructions: dto.instructions,
    giftMessage: dto.gift_message,
    orderSource: dto.order_source,
    customerOrderReference: dto.customer_order_reference,
    status: dto.status,
    statusUpdatedAt: dto.status_updated_at,
    createdAt: dto.created_at,
    errorType: dto.error_type,
    errorMsg: dto.error_msg,
    shipTo: {
      address: dto.address.address_1,
      address2: dto.address.address_2 ?? '',
      address3: dto.address.address_3,
      city: dto.address.city,
      country: dto.address.country,
      email: dto.address.email ?? '',
      name: dto.address.name,
      phoneNumber: dto.address.phone_number,
      state: dto.address.state,
      zipCode: dto.address.postal_code,
    },
    lines: dto.line_items.map((lineItem) => ({
      lineNo: lineItem.line_number.toString(),
      qtyRequested: lineItem.qty_requested,
      qtyFulfilled: lineItem.qty_fulfilled,
      sku: lineItem.sku,
      customerItemNumber: lineItem.external_item_number,
      childSkus: lineItem.child_skus
        ? lineItem.child_skus.map((child) => ({
            lineNumber: child.line_number,
            sku: child.sku,
            qtyPerParent: child.qty_per_parent,
            qtyRequested: child.qty_requested,
            qtyFulfilled: child.qty_fulfilled,
          }))
        : undefined,
    })),
    shipments: dto.shipments.map((shipment) => ({
      shipmentNumber: shipment.shipment_number,
      poNumber: shipment.po_number,
      targetDc: shipment.target_dc,
      status: shipment.status,
      createdAt: shipment.created_at,
      lineItems: shipment.line_items.map((lineItem) => ({
        lineNumber: lineItem.line_number,
        externalItemNumber: lineItem.external_item_number,
        sku: lineItem.sku,
        orderQty: lineItem.order_qty,
        shipQty: lineItem.ship_qty,
        shortQty: lineItem.short_qty,
      })),
    })),
  };
}

export function buildInittialLines(): CreateNetworkOrderLineItem[] {
  return [
    {
      lineNo: '1',
      sku: '',
      orderQty: 1,
    },
  ];
}

export function buildEmptyOrder(): CreateNetworkOrder {
  return {
    requestTransactionId: '',
    name: '',
    shipVia: '',
    shipOption: '',
    carrierAccountUUID: '',
    warehouseID: '',
    dueDate: '',
    shipTo: {
      name: '',
      city: '',
      state: '',
      zipCode: '',
      country: '',
      phoneNumber: '',
      address: '',
      address2: '',
      email: '',
    },
    lines: buildInittialLines(),
  };
}
