import {
  Text,
  Button,
  Box,
  Wrap,
  Tooltip,
  List,
  ListItem,
  Flex,
} from '@chakra-ui/react';
import get from 'lodash-es/get';
import {
  FieldArrayWithId,
  useFieldArray,
  useFormContext,
} from 'react-hook-form';
import ClearIcon from '@mui/icons-material/Clear';
import CachedIcon from '@mui/icons-material/Cached';
import HelpIcon from '@mui/icons-material/Help';
import { FormInput } from 'components/FormInput';
import { SalesOrder } from 'types/salesOrders';
import { PurchaseOrder } from 'types/purchaseOrders';
import { useEffect, useMemo, useRef } from 'react';
import { useInventoryBySku } from 'hooks/useInventoryBySku';
import { Shimmer } from 'components/Shimmer';
import { AddBoxButton } from 'components/AddBoxButton';

import SharedStyles from 'styles/shared.module.css';
import { PaginatedTable } from 'components/PaginatedTable';
import { Column } from 'react-table';
import { QueryState } from 'types/queryState';
import { isSelectNodeOption } from 'queries/warehouses/useWarehouses';
import { CreateNetworkOrder } from 'types/networkOrder';

const commonInputProps = {
  variant: 'outline',
  hideLabel: true,
};

type Props = {
  title?: string;
  isFreightOrder?: boolean;
  isPurchaseOrder?: boolean;
  isNetworkOrder?: boolean;
  startTabIndex?: number;
};

export function OrderLines({
  title,
  isFreightOrder,
  isPurchaseOrder,
  isNetworkOrder,
  startTabIndex,
}: Props) {
  const {
    register,
    control,
    formState: { errors },
    getValues,
    watch,
    setValue,
  } = useFormContext<SalesOrder | PurchaseOrder | CreateNetworkOrder>();

  const warehouseID = watch('warehouseID');
  const lines = watch('lines');

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'lines',
  });

  const {
    getQuantityAvailable,
    fetchInventoryItems,
    isFetching,
    resetInventoryItems,
  } = useInventoryBySku();

  const previousDc = useRef<string>();
  useEffect(() => {
    if (
      warehouseID !== previousDc.current &&
      !isPurchaseOrder &&
      !isNetworkOrder &&
      !isSelectNodeOption(warehouseID)
    ) {
      previousDc.current = warehouseID;
      resetInventoryItems();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [warehouseID, isPurchaseOrder, lines]);

  const handleAddSKUClick = () => {
    const nextLineNo = Math.max(...fields.map((line) => +line.lineNo), 0) + 1;

    append({
      lineNo: nextLineNo.toString(),
      sku: '',
      customerItemNumber: '',
      orderQty: 1,
    });
  };

  const handleRemoveClick = (lineIndex: number) => remove(lineIndex);
  const errorMessage = get(errors, 'lines')?.message;

  function calculateTabIndex(rowIndex: number, tabIndex: number) {
    const totalInputColumns = 8;
    return rowIndex * totalInputColumns + tabIndex + (startTabIndex ?? 0);
  }

  const columnWidth = useMemo(() => {
    return {
      lineNo: isFreightOrder ? 100 : 120,
      sku: isFreightOrder
        ? 220
        : isPurchaseOrder || isSelectNodeOption(warehouseID)
        ? 340
        : 275,
      customerItemNumber: isFreightOrder
        ? 220
        : isPurchaseOrder || isSelectNodeOption(warehouseID)
        ? 340
        : 275,
      orderQty: isFreightOrder
        ? 140
        : isPurchaseOrder || isSelectNodeOption(warehouseID)
        ? 300
        : 190,
    };
  }, [isFreightOrder, isPurchaseOrder, warehouseID]);

  const skuColumn = useMemo(
    () => ({
      Header: 'SKU',
      accessor: 'sku',
      width: columnWidth.sku,
      isRequired: true,
      Cell: ({
        row,
      }: {
        value: string;
        row: { id: string; index: number };
      }) => (
        <FormInput
          tabIndex={calculateTabIndex(row.index, 2)}
          label="SKU"
          {...commonInputProps}
          {...register(`lines.${parseInt(row.id)}.sku`)}
        />
      ),
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [columnWidth]
  );

  const orderLinesColumns = useMemo(
    () =>
      [
        {
          Header: 'Line #',
          accessor: 'lineNo',
          width: columnWidth.lineNo,
          Cell: ({ row, value }: { row: { index: number }; value: string }) => {
            const errorMessage = (errors?.lines || [])[row.index]?.lineNo
              ?.message;
            return (
              <Flex direction="column">
                <Text>{value}</Text>
                {errorMessage ? (
                  <Text variant="fieldError">{errorMessage}</Text>
                ) : null}
              </Flex>
            );
          },
        },
        skuColumn,
        {
          Header: 'Customer Item #',
          accessor: 'customerItemNumber',
          width: columnWidth.customerItemNumber,
          Cell: ({ row }: { row: { id: string; index: number } }) => (
            <FormInput
              tabIndex={calculateTabIndex(row.index, 3)}
              label="Customer Item #"
              {...commonInputProps}
              {...register(`lines.${parseInt(row.id)}.customerItemNumber`)}
            />
          ),
        },
        isFreightOrder
          ? {
              Header: 'ARN',
              accessor: 'arn',
              width: 110,
              Cell: ({ row }: { row: { id: string; index: number } }) => (
                <FormInput
                  tabIndex={calculateTabIndex(row.index, 4)}
                  label="ARN"
                  {...commonInputProps}
                  {...register(`lines.${parseInt(row.id)}.arn`)}
                />
              ),
            }
          : null,
        isFreightOrder
          ? {
              Header: 'ASN',
              accessor: 'asn',
              width: 110,
              Cell: ({ row }: { row: { id: string; index: number } }) => (
                <FormInput
                  tabIndex={calculateTabIndex(row.index, 5)}
                  label="ASN"
                  {...commonInputProps}
                  {...register(`lines.${parseInt(row.id)}.asn`)}
                />
              ),
            }
          : null,
        !isPurchaseOrder && !isNetworkOrder && !isSelectNodeOption(warehouseID)
          ? {
              Header: () => (
                <>
                  Available Qty{' '}
                  <Tooltip
                    label={
                      <List whiteSpace="nowrap">
                        <ListItem>
                          Required fields to fetch the available quantity:
                        </ListItem>
                        <ListItem fontWeight={300}>FC</ListItem>
                        <ListItem fontWeight={300}>SKU</ListItem>
                      </List>
                    }
                  >
                    <HelpIcon fontSize="inherit" />
                  </Tooltip>
                </>
              ),
              accessor: 'availableQty',
              width: isFreightOrder ? 190 : 230,
              Cell: ({ row }: { row: { id: string } }) => {
                if (isFetching) {
                  return <Shimmer height="40px" rounded="none" width="187px" />;
                }

                const sku = getValues().lines[parseInt(row.id)].sku;
                const availableQuantity = getQuantityAvailable(sku);

                return (
                  <Wrap position="relative">
                    <FormInput
                      {...commonInputProps}
                      backgroundColor="gray.100"
                      cursor="not-allowed"
                      isReadOnly
                      tabIndex={-1}
                      label="Available Qty"
                      value={availableQuantity >= 0 ? availableQuantity : ''}
                      marginTop="0!"
                    />
                    <Button
                      tabIndex={-1}
                      backgroundColor="transparent"
                      border="none"
                      height="32px"
                      onClick={() => {
                        fetchInventoryItems(getValues().warehouseID, sku, true);
                      }}
                      padding={0}
                      position="absolute"
                      right={0}
                      rounded="none"
                      top={1}
                      width={0}
                    >
                      <Tooltip label="Fetch available quantity">
                        <CachedIcon />
                      </Tooltip>
                    </Button>
                  </Wrap>
                );
              },
            }
          : null,
        {
          Header: 'Order Qty',
          accessor: 'orderQty',
          width: columnWidth.orderQty,
          isRequired: !isSelectNodeOption(getValues().warehouseID),
          Cell: ({ row }: { row: { id: string; index: number } }) => {
            return (
              <FormInput
                tabIndex={calculateTabIndex(row.index, 6)}
                label="Order Qty"
                {...commonInputProps}
                {...register(`lines.${parseInt(row.id)}.orderQty`)}
                onChange={(event) => {
                  setValue(event.target.name as any, event.target.value);
                }}
              />
            );
          },
        },
        {
          Header: 'Delete',
          width: 80,
          Cell: ({ row }: { row: { id: string; index: number } }) => (
            <Button
              aria-label="Delete"
              tabIndex={calculateTabIndex(row.index, 7)}
              onClick={() => handleRemoveClick(parseInt(row.id))}
              fontSize="md"
              variant="redSquareButton"
              isDisabled={fields.length === 1}
            >
              <ClearIcon fontSize="inherit" />
            </Button>
          ),
        },
      ].filter(Boolean) as Column<any>[],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isFetching, errors, columnWidth, fields]
  );

  return (
    <Box>
      {title && (
        <Text variant="boldBlue" fontSize="xl">
          {title}
        </Text>
      )}
      <Box
        className={SharedStyles['horizontal-scroll']}
        mt={3}
        overflow="scroll"
        maxH="350px"
      >
        <PaginatedTable
          columns={orderLinesColumns}
          queryState={
            {
              data: fields || [],
            } as QueryState<
              FieldArrayWithId<SalesOrder | PurchaseOrder | CreateNetworkOrder>
            >
          }
          tableName="order_lines_table_form"
          isResizable
        />
        {errorMessage && (
          <Text variant="fieldError" margin={2} mt={5}>
            {errorMessage}
          </Text>
        )}
      </Box>

      <AddBoxButton onClick={handleAddSKUClick} title="Add SKU">
        Add SKU
      </AddBoxButton>
    </Box>
  );
}
