import { CreateNetworkOrder } from 'features/create-network-order/CreateNetworkOrder';
import { usePortal } from 'hooks/usePortal';
import { useShipVias } from 'queries/shipVias/useShipVias';
import { BigLoading } from 'components/BigLoading';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

export function CreateNetworkOrderPage() {
  const location = useLocation();
  const navigate = useNavigate();
  const urlSegments = location.pathname.split('/');
  const firstUrlSegment = urlSegments[1] ?? '';

  const { isPortalReadOnly } = usePortal();
  const { isLoading } = useShipVias();

  const handleClose = () => {
    navigate(`/${firstUrlSegment}${location.search ?? ''}`);
  };

  useEffect(() => {
    isPortalReadOnly && handleClose();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPortalReadOnly]);

  return (
    <>
      {!isLoading ? (
        <CreateNetworkOrder onAbort={handleClose} onConfirm={handleClose} />
      ) : (
        <BigLoading />
      )}
    </>
  );
}
