export async function readFileAsText(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    if (!file) {
      return reject({
        message: 'No file provided',
      });
    }

    const reader = new FileReader();
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = () =>
      reject({
        message: 'Error reading file',
      });

    reader.readAsText(file);
  });
}
