import { Box, Text } from '@chakra-ui/react';
import { FormSelect } from 'components/FormSelect';
import { TEMPLATE_ITEMS, TEMPLATE_ITEMS_BY_ORG } from 'constants/templateLinks';
import { useTenant } from 'queries/tenant/useTenant';
import { useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';

function getTemplateItemsByOrgId(orgCode: string | undefined) {
  if (orgCode === undefined) return [];
  return TEMPLATE_ITEMS_BY_ORG[orgCode.toUpperCase()] || [];
}

export function TemplateSelect() {
  const { register, watch, setValue } = useFormContext();
  const { data } = useTenant();

  const templateItems = useMemo(
    () =>
      [...TEMPLATE_ITEMS, ...getTemplateItemsByOrgId(data?.code)].map(
        (item) => ({
          label: item.label,
          value: item.templateUrl,
          metadata: item,
        })
      ),
    [data]
  );

  const template = watch('template');

  useEffect(() => {
    if (!template) return;
    const item = templateItems.find((item) => item.value === template);
    if (item) {
      setValue('instruction', item.metadata.instructionUrl);
      setValue('submit', item.metadata.submitUrl);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [template]);

  return (
    <Box>
      <Text as="h3" variant="pageHeading" mb={2}>
        (1) Choose an Action
      </Text>
      <FormSelect
        aria-label="Template"
        variant="outline"
        {...register('template')}
        options={templateItems}
      />
    </Box>
  );
}
