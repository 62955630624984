import { Text, VStack } from '@chakra-ui/react';
import {
  PurchaseOrderUploadError,
  PurchaseOrderPreview,
} from 'types/purchaseOrders';
import { ErrorUploadPurchaseOrders } from './components/ErrorUploadPurchaseOrders';
import { SuccessUploadPurchaseOrders } from './components/SuccessUploadPurchaseOrders';

type Props = {
  data: PurchaseOrderPreview[];
  error: PurchaseOrderUploadError[] | null;
  onBack: () => void;
};

export function ReviewPurchaseOrders({ data, error, onBack }: Props) {
  return (
    <VStack align="start" spacing={3}>
      <Text as="h3" variant="pageHeading" my={1}>
        4. Review Results
      </Text>
      {error ? (
        <ErrorUploadPurchaseOrders error={error} onBack={onBack} />
      ) : (
        <SuccessUploadPurchaseOrders data={data} />
      )}
    </VStack>
  );
}
