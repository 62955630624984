import { Modal } from 'components/Modal';
import { BulkCancelInput } from 'features/bulk-cancel-so/BulkCancelInput';
import { BulkCancelTable } from 'features/bulk-cancel-so/BulkCancelTable';
import { useConfirmationDialog } from 'hooks/useConfirmationDialog';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import uniq from 'lodash/uniq';
import { useTenant } from 'queries/tenant/useTenant';
import { downloadReport } from 'utils/reportUtils';
import { FileType } from 'utils/fileTypes';
import { format as formatDate } from 'date-fns';
import { useCancelSalesOrders } from 'mutations/salesOrders/useCancelSalesOrders';
import { stringify } from 'csv-stringify/browser/esm/sync';
import { useToast } from 'hooks/useToast';

export function BulkCancelPage() {
  const toast = useToast();
  const location = useLocation();
  const navigate = useNavigate();
  const { data: tenant } = useTenant();
  const { openConfirmationDialog } = useConfirmationDialog();

  const [soNumbersRaw, setSoNumbersRaw] = useState('');
  const { results, mutateAsync, isLoading, isSuccess } = useCancelSalesOrders();

  const handleClose = () => {
    openConfirmationDialog({
      title: 'Close Bulk Cancel Shipments',
      message:
        'Are you sure to want to close the bulk cancel shipments operation?',
      onConfirm: () => navigate(`/orders` + location.search),
      blockScrollOnMount: false,
      confirmButtonLabel: 'Yes',
      cancelButtonLabel: 'No',
    });
  };

  const handleSubmit = () => {
    const soNumbers = uniq(
      soNumbersRaw
        .replace(/[^\S\n]/g, '')
        .split('\n')
        .filter(Boolean)
    ).map((soNumber) => {
      let parsedSoNumber = soNumber;
      const tentantPrefix = `${tenant!.code}-`;
      if (parsedSoNumber.startsWith(tentantPrefix)) {
        parsedSoNumber = soNumber.replace(tentantPrefix, '');
      }
      return parsedSoNumber;
    });
    if (soNumbers.length > 100) {
      toast({
        status: 'error',
        title: 'Error',
        description:
          'Limit exceeded: You can cancel up to 100 shipments at a time.',
      });
      return;
    }
    mutateAsync(soNumbers);
  };

  const exportCSV = () => {
    downloadReport({
      fileName: `${tenant?.code}_so_bulk_cancel_${formatDate(
        new Date(),
        'yyyyMMddHHmmss'
      )}.csv`,
      data: stringify(Object.values(results), {
        header: true,
        columns: {
          soNumber: 'Shipment #',
          orderStatus: 'Status',
          messages: 'Message',
        },
        cast: {
          object: function (value) {
            if (Array.isArray(value)) return value.join('|');
            return JSON.stringify(value);
          },
        },
      }),
      fileType: FileType.Csv,
    });
  };

  return (
    <Modal
      title="Bulk Cancel Shipments"
      isOpen={true}
      closeOnEsc={!isLoading}
      closeOnOverlayClick={!isLoading}
      showCloseButton={!isLoading}
      onClose={handleClose}
      buttons={[
        !isSuccess
          ? {
              label: 'Cancel SOs',
              variant: 'cta',
              isDisabled: !soNumbersRaw.trim(),
              isLoading,
              handler: handleSubmit,
            }
          : {
              label: 'Export CSV',
              handler: exportCSV,
            },
        {
          label: 'Done',
          isDisabled: isLoading,
          handler: handleClose,
        },
      ]}
      size={['full', null, '7xl']}
    >
      {!isSuccess ? (
        <BulkCancelInput onChange={(value) => setSoNumbersRaw(value)} />
      ) : (
        <BulkCancelTable data={Object.values(results)} />
      )}
    </Modal>
  );
}
