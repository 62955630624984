/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useMemo, useState } from 'react';
import { Button, Flex, GridItem, SimpleGrid, Text } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider } from 'react-hook-form';
import { ButtonGroup } from 'components/ButtonGroup';
import { CreateNetworkOrderForm } from './CreateNetworkOrderForm';
import { useCrudActionUserFeedback } from 'hooks/useCrudActionUserFeedback';
import { mapFieldDtoErrorToRhfPath } from 'mutations/salesOrders/mappers';
import RestoreIcon from '@mui/icons-material/Restore';
import { useConfirmationDialog } from 'hooks/useConfirmationDialog';
import { sendCreateNetworkOrderEvent } from 'utils/monitorEvents';
import merge from 'lodash-es/merge';
import { useShipVias } from 'queries/shipVias/useShipVias';
import { BasicInfoForm } from './BasicInfoForm';
import { ShipToForm } from 'features/order-details/components/ModifyOrder/ShipToForm';
import { InstructionsForm } from 'features/create-order/InstructionsForm';
import {
  buildEmptyOrder,
  buildInittialLines,
} from 'queries/networkOrders/mappers';
import { CreateNetworkOrder as CreateNetworkOrderType } from 'types/networkOrder';
import { useNetworkOrderForm } from 'hooks/useNetworkOrderForm';
import { useCreateNetworkOrder } from 'mutations/networkOrders/useCreateNetworkOrder';
import { createNetworkOrderSchema } from 'schemas/createNetworkOrder';
import { OrderLines } from 'features/order-lines/OrderLines';

type Props = {
  onConfirm: () => void;
  onAbort: () => void;
};

const sessionStorageKey = 'create_network_order_draft';

export const CreateNetworkOrder: React.FC<Props> = ({ onAbort, onConfirm }) => {
  const { openConfirmationDialog } = useConfirmationDialog();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const draftJson = sessionStorage.getItem(sessionStorageKey);
  const { getDefaultShipVia } = useShipVias();

  const defaultOrderValue = useMemo(() => {
    const emptyOrder = buildEmptyOrder();
    const draftData: CreateNetworkOrderType | null = draftJson
      ? { ...JSON.parse(draftJson) }
      : null;
    if (draftData) {
      return merge(
        emptyOrder,
        draftData.lines.length
          ? draftData
          : { ...draftData, lines: buildInittialLines() }
      );
    }
    return {
      ...emptyOrder,
      shipVia: getDefaultShipVia(),
    };
  }, []);

  const reactHookFormRet = useNetworkOrderForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: defaultOrderValue,
    resolver: yupResolver(createNetworkOrderSchema),
  });

  const {
    handleSubmit,
    getValues,
    reset,
    formState: { isDirty },
  } = reactHookFormRet;

  const confirmAbort = useCallback(
    (callback: () => void) => {
      if (isDirty || draftJson) {
        sessionStorage.setItem(sessionStorageKey, JSON.stringify(getValues()));
      }
      callback();
    },
    [isDirty]
  );

  const { mutateAsync } = useCreateNetworkOrder();

  const handleCreateOrder = useCrudActionUserFeedback<CreateNetworkOrderType>({
    mutateAsync: () => mutateAsync(getValues()),
    actionType: 'CREATE',
    successMessage: 'Order successfully created',
    successCallback: () => {
      sendCreateNetworkOrderEvent(getValues().name);
      onConfirm();
      sessionStorage.removeItem(sessionStorageKey);
    },
    startCallback: () => {
      setIsSubmitting(true);
    },
    finallyCallback: () => {
      setIsSubmitting(false);
    },
    setFieldError: reactHookFormRet.setError,
    mapFieldDtoErrorToRhfPath,
  });

  const handleResetClick = () => {
    openConfirmationDialog({
      title: 'Discard changes',
      message: 'Discard changes?',
      onConfirm: () => {
        const draft = {
          ...buildEmptyOrder(),
          shipVia: getDefaultShipVia(),
        };
        reset(draft);
        sessionStorage.removeItem(sessionStorageKey);
      },
      blockScrollOnMount: false,
      confirmButtonLabel: 'Discard',
    });
  };

  return (
    <FormProvider {...reactHookFormRet}>
      <Flex mb={6} gap={2} justifyContent="space-between">
        <Text as="h2" variant="pageTitle">
          Create Order
        </Text>
        <Button
          variant="ghost"
          mr={-5}
          ml={[-4, null, 0]}
          mt={[-4, null, 0]}
          onClick={handleResetClick}
        >
          <Text mr={2}>Reset</Text> <RestoreIcon />
        </Button>
      </Flex>
      <form onSubmit={handleSubmit(handleCreateOrder)}>
        <SimpleGrid columns={1} gap={8} mt={[5, null, 0]}>
          <GridItem>
            <Text variant="boldBlue" fontSize="xl">
              General
            </Text>
            <BasicInfoForm startTabIndex={0} />
          </GridItem>
          <GridItem>
            <Text variant="boldBlue" fontSize="xl">
              Ship To
            </Text>
            <ShipToForm startTabIndex={2} />
          </GridItem>
          <InstructionsForm tabIndex={12} />
          <GridItem>
            <Text variant="boldBlue" fontSize="xl">
              Shipping Options
            </Text>
            <CreateNetworkOrderForm startTabIndex={12} />
          </GridItem>
          <GridItem>
            <Text variant="boldBlue" fontSize="xl">
              SKUs
            </Text>
            <OrderLines startTabIndex={16} isNetworkOrder />
          </GridItem>
          <SimpleGrid columns={{ base: 1, xl: 2 }}>
            <ButtonGroup
              wrapperProps={{ display: 'grid' }}
              commonButtonProps={{ width: '100%' }}
              buttons={[
                {
                  label: 'Create',
                  variant: 'cta',
                  isLoading: isSubmitting,
                },
                {
                  label: 'Cancel',
                  handler: () => confirmAbort(onAbort),
                },
              ]}
            />
          </SimpleGrid>
        </SimpleGrid>
      </form>
    </FormProvider>
  );
};
