import axios from 'axios';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { SalesOrder, SalesOrderDetailDto } from 'types/salesOrders';
import { mapDtoToSalesOrder } from './mappers';
import { CONFIG } from 'utils/config';

export function useSalesOrder(
  soNumber?: string | null,
  options?: UseQueryOptions<SalesOrder>
) {
  return useQuery<SalesOrder>(
    ['salesOrder', soNumber],
    async () => {
      const { data } = await axios.get<SalesOrderDetailDto>(
        `/salesorder/${encodeURIComponent(soNumber!)}`,
        {
          baseURL: CONFIG().baseUrl + 'v2/',
        }
      );

      return mapDtoToSalesOrder(data);
    },
    {
      ...options,
      enabled: Boolean(soNumber),
    }
  );
}
