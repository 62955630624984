import { useMemo } from 'react';
import get from 'lodash-es/get';
import { Column } from 'react-table';
import { useLocation, useNavigate } from 'react-router-dom';
import { Link } from 'components/Link';
import { useSalesOrders } from 'queries/salesOrders';
import { SalesOrder } from 'types/salesOrders';
import { ToggleVisibility } from 'components/ToggleVisibility';
import { useGridSorting } from 'hooks/useGridSorting';
import { useGridPagination } from 'hooks/useGridPagination';
import {
  OrderPageParams,
  dictFieldToQuery,
} from 'features/orders-list/orderPageParams';
import { ColumnMetaData, useGridFiltering } from 'hooks/useGridFiltering';
import { PaginatedTable } from 'components/PaginatedTable';
import { formatDate } from 'utils/dateUtils';
import { CONFIG } from 'utils/config';
import { useDownloadReport } from 'utils/reportUtils';
import { REPORT_TYPES } from 'constants/reports';
import { useWarehouses } from 'queries/warehouses/useWarehouses';
import { useLayoutPaginatedTable } from 'hooks/useLayoutPaginatedTable';
import { BigLoading } from 'components/BigLoading';
import { usePortal } from 'hooks/usePortal';
import { Box, Flex, Text } from '@chakra-ui/react';
import { ButtonGroup } from 'components/ButtonGroup';
import { useTenant } from 'queries/tenant/useTenant';
import { Storefront } from 'types/tenant';
import { useAuth0Roles } from 'hooks/useAuth0Roles';
import { AUTH0_ROLES } from 'constants/auth0Roles';
import { STATUS } from 'constants/order-statuses';

export interface OrdersGridProps extends OrderPageParams {}

export function OrdersGrid(props: OrdersGridProps) {
  const isAdmin = useAuth0Roles([AUTH0_ROLES.STANDVAST_ADMIN]);
  const { isPortalReadOnly } = usePortal();
  const { buildParameters, isDownloadingReport, onDownloadReport, resources } =
    useDownloadReport();
  const navigate = useNavigate();
  const location = useLocation();
  const availableStatus = [...STATUS];
  const initialStatus = get(props.initialFilters, 'status');
  const { data: warehouses } = useWarehouses({
    refetchOnMount: false,
  });
  const { data: tenant } = useTenant({
    refetchOnMount: false,
  });

  const buttons = [
    isAdmin
      ? {
          label: 'Bulk Cancel',
          handler: () => {
            navigate('/orders/bulk-cancel' + location.search);
          },
        }
      : null,
    {
      label: resources.labels.button,
      handler: () => {
        onDownloadReport(
          REPORT_TYPES.SALES_ORDERS,
          buildParameters(dictFieldToQuery, filters)
        );
      },
      isLoading: isDownloadingReport,
      tooltip: isDownloadingReport
        ? resources.labels.tooltip.isDownloading
        : resources.labels.tooltip.idle,
    },
    CONFIG().featureToggles.enableCreateSalesOrder && !isPortalReadOnly
      ? {
          label: 'Create Shipment',
          variant: 'cta',
          handler: () => {
            navigate('/orders/create');
          },
        }
      : null,
  ].filter(Boolean);

  const gridSorting = useGridSorting<SalesOrder>({
    getQueryState: () => queryState,
    initialSort: props.initialSort,
    onSortChange: props.onSortByChange,
  });

  const gridPagination = useGridPagination({
    initialPage: props.initialPage,
    initialPageSize: props.initialPageSize,
    onPaginationChange: props.onPaginationChange,
  });

  const columnsMetaData: { [key: string]: ColumnMetaData } = {
    soNumber: { dataType: 'text' },
    poNumber: { dataType: 'text' },
    type: {
      dataType: 'select',
      selectOptions: ['Parcel', 'Freight'],
    },
    storefront: {
      dataType: 'select',
      selectOptions:
        tenant?.storefronts?.map((storefront) => ({
          label: storefront.displayName,
          value: storefront.name,
        })) ?? [],
    },
    status: {
      dataType: 'select',
      selectOptions: availableStatus as Array<string>,
      formatOptions: true,
    },
    targetDc: {
      dataType: 'select',
      selectOptions:
        warehouses?.map((warehouse) => ({
          label: warehouse.displayText,
          value: warehouse.code,
        })) ?? [],
    },
    createdDate: { dataType: 'date-range' },
    dueDate: { dataType: 'date-range' },
    shipDate: { dataType: 'date-range' },
    trackingNumbers: {
      dataType: 'text',
      isSortable: false,
      isFilterable: false,
    },
    totalOrderQty: { dataType: 'number' },
    totalShippedQty: { dataType: 'number' },
    totalShortQty: { dataType: 'number' },
    totalPickedQty: { dataType: 'number' },
    totalUnpickedQty: { dataType: 'number' },
    totalCanceledQty: { dataType: 'number' },
  };

  const gridFiltering = useGridFiltering<SalesOrder>({
    columnsMetaData,
    initialFilters: props.initialFilters,
    onFilterChange: props.onFilterChange,
  });

  const filters = {
    ...gridFiltering.filters,
    status: initialStatus ? initialStatus.split(',') : availableStatus,
  };

  const queryState = useSalesOrders(
    { skip: gridPagination.skip, limit: gridPagination.limit },
    filters,
    gridSorting.sort
  );

  const { widthWindow, getColumnWidth } = useLayoutPaginatedTable(2625);

  const ordersColumns = useMemo(
    () =>
      [
        {
          accessor: 'soNumber',
          Header: 'Shipment Number',
          width: getColumnWidth(10, 160),
          Cell: ({ value: soNumber }: { value: string }) => (
            <Link
              to={`/orders/${encodeURIComponent(soNumber)}`}
              fontWeight="bold"
              _hover={{ textDecoration: 'underline' }}
            >
              {soNumber}
            </Link>
          ),
        },
        tenant?.storefronts?.length
          ? {
              Header: 'Storefront',
              id: 'storefront',
              accessor: 'storefront',
              width: getColumnWidth(10, 180),
              Cell: ({
                value: storefront,
              }: {
                value: Storefront | undefined;
              }) => <>{storefront?.displayName}</>,
            }
          : null,
        {
          Header: 'Status',
          id: 'status',
          accessor: 'status',
          width: getColumnWidth(7, 140),
        },
        {
          Header: 'Shipment Type',
          accessor: 'type',
          width: getColumnWidth(7, 160), //
        },
        {
          Header: 'Created Date',
          accessor: 'createdDate',
          width: getColumnWidth(7.75, 160), //
          Cell: ({ value }: { value: string }) => <>{formatDate(value)}</>,
          getValue: formatDate,
        },
        {
          Header: 'Due Date',
          accessor: 'dueDate',
          width: getColumnWidth(7.5, 160),
          Cell: ({ value }: { value: string }) => <>{formatDate(value)}</>,
          getValue: formatDate,
        },
        {
          Header: 'Customer Name',
          accessor: 'shipTo',
          width: getColumnWidth(9, 200), //
          Cell: ({
            value: { name: shipToName },
          }: {
            value: { name: string };
          }) => (
            <ToggleVisibility hiddenCover="*****">
              <span title={shipToName}>{shipToName}</span>
            </ToggleVisibility>
          ),
        },
        {
          Header: 'Fulfillment Center',
          accessor: 'targetDc',
          isNumeric: false,
          Cell: ({ value }: { value: string }) => {
            const warehouseInfo = warehouses?.find(
              (item) => item.code === value
            );
            return <>{warehouseInfo?.displayText || value}</>;
          },
          width: getColumnWidth(10, 180),
        },
        {
          Header: 'Ship Via',
          accessor: 'shipVia',
          width: getColumnWidth(6, 140),
        },
        {
          Header: 'Ship Date',
          accessor: 'shipDate',
          width: getColumnWidth(6.8, 160), //
          Cell: ({ value }: { value: string }) => <>{formatDate(value)}</>,
        },
        {
          Header: 'Tracking #',
          accessor: 'trackingNumbers',
          width: getColumnWidth(6.8, 250),
          Cell: ({ value }: { value: string[] }) => <>{value.join(', ')}</>,
        },
        {
          Header: 'Order Qty',
          accessor: 'totalOrderQty',
          isNumeric: true,
          width: getColumnWidth(7.25, 170), //
          helpText: 'Total number of items included in the order',
        },
        {
          Header: 'Ship Qty',
          accessor: 'totalShippedQty',
          isNumeric: true,
          width: getColumnWidth(5.0, 170), //
          helpText: 'Total number of shipped items included in the order',
        },
        {
          Header: 'Short Qty',
          accessor: 'totalShortQty',
          isNumeric: true,
          width: getColumnWidth(5.0, 170), //
          helpText: 'Total number of short items included in the order',
        },
        {
          Header: 'Unpicked Qty',
          accessor: 'totalUnpickedQty',
          isNumeric: true,
          width: getColumnWidth(8.75, 195), //
          helpText: 'The total number of items yet to be picked in the order',
        },
        {
          Header: 'Picked Qty',
          accessor: 'totalPickedQty',
          isNumeric: true,
          width: getColumnWidth(6.75, 160), //
        },
        {
          Header: 'Canceled Qty',
          accessor: 'totalCanceledQty',
          isNumeric: true,
          width: getColumnWidth(8.75, 195), //
          helpText:
            'The total number of items cancelled for any reason that will not be shipped. The specific items in question are identified when looking at the individual SO',
        },
        {
          Header: 'Reference Number',
          accessor: 'poNumber',
          width: getColumnWidth(10, 110),
          helpText: 'Purchase Order',
        },
      ].filter(Boolean) as Column<SalesOrder>[],
    [getColumnWidth, warehouses, tenant]
  );

  return widthWindow ? (
    <Box>
      <Flex
        justifyContent="space-between"
        alignItems={[null, 'center']}
        flexDirection={['column', null, 'row']}
      >
        <Text mb={[buttons.length ? 4 : 0, 0]} as="h2" variant="pageTitle">
          Shipments
        </Text>
        {buttons.length ? (
          <ButtonGroup
            buttons={buttons as any}
            wrapperProps={{
              display: 'flex',
              flexDirection: ['column-reverse', null, 'row'],
              width: 'auto',
            }}
            commonButtonProps={{ fontSize: 'lg' }}
            commonButtonWrapperProps={{ mr: 0 }}
          />
        ) : null}
      </Flex>
      <PaginatedTable
        columns={ordersColumns}
        hiddenColumns={[
          'poNumber',
          'shipTo',
          'totalUnpickedQty',
          'totalPickedQty',
          'totalCanceledQty',
        ]}
        gridPagination={gridPagination}
        gridSorting={gridSorting}
        gridFiltering={gridFiltering}
        queryState={queryState}
        tableName="sales_orders"
        isResizable
      />
    </Box>
  ) : (
    <BigLoading />
  );
}
