import { WAREHOUSE_TIME_ZONES } from 'constants/warehouseTimeZones';
import { isAfter, set } from 'date-fns';
import { toZonedTime } from 'date-fns-tz';

export function getWarehouseTimezone(warehouseCode: string) {
  return WAREHOUSE_TIME_ZONES.find((item) =>
    warehouseCode.toUpperCase().includes(item.warehouseCode)
  )?.timezone;
}

/**
 * Take the FC choice into a cutoff time:
 * + Reno is pacific time, so 12pm pacific
 * + Memphis is central time, so 12pm central
 * + Baltimore is eastern time, so 12pm eastern
 * Determine a window:
 * + If current time before cutoff time, then window is {deliveryDays} weekday
 * + If current time is after cutoff time, then window is {deliveryDays + 1} weekdays
 */
export function getDeliveryWindow(
  warehouseCode: string,
  deliveryDays: number
): number {
  const timezone = getWarehouseTimezone(warehouseCode);
  if (!timezone) return deliveryDays;

  const now = toZonedTime(new Date(), timezone);
  const cutoffTime = set(now, {
    hours: 12,
    minutes: 0,
    seconds: 0,
    milliseconds: 0,
  });

  return isAfter(now, cutoffTime) ? deliveryDays + 1 : deliveryDays;
}
