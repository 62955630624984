import { GridItem, SimpleGrid } from '@chakra-ui/react';
import { sendUpdateUserProfileEvent } from 'utils/monitorEvents';
import { Modal } from 'components/Modal';
import { UserProfilePhoto } from './user-profile-photo/UserProfilePhoto';
import { UserProfileGeneral } from './user-profile-general/UserProfileGeneral';
import { SectionCollapsable } from 'components/SectionCollapsable';
import { useUserProfileForm } from 'hooks/useUserProfileForm';
import { yupResolver } from '@hookform/resolvers/yup';
import { modifyUserProfile } from 'schemas/modifyUserProfile';
import { FormProvider } from 'react-hook-form';
import { useUpdateUserProfile } from 'mutations/userProfile/useUpdateUserProfile';
import { UserProfileModify } from 'types/userProfile';
import { useCrudActionUserFeedback } from 'hooks/useCrudActionUserFeedback';
import {
  mapFieldDtoErrorToRhfPath,
  mapUserProfileToModify,
  mapUserProfileToUserSystem,
} from 'mutations/userProfile/mappers';
import { useEffect, useMemo, useRef, useState } from 'react';
import { ButtonGroup } from 'components/ButtonGroup';
import { UserProfileSystem } from './user-profile-system/UserProfileSystem';
import { BigLoading } from 'components/BigLoading';
import { useUserProfileByUserId } from 'queries/userProfiles/useUserProfile';
import { useAuth0 } from '@auth0/auth0-react';
import { UserProfileRole } from './user-profile-role/UserProfileRole';
import { OptionSelect } from 'components/FormSelectMulti';
import { useUpdateUserRoles } from 'mutations/userProfile/useUpdateUserRoles';
import { useQueryClient } from '@tanstack/react-query';
import { useToast } from 'hooks/useToast';

type UserProfileModalProps = {
  userId?: string;
  isOpen?: boolean;
  preventCloseWhenFormInvalid?: boolean;
  isEnableRole?: boolean;
  onClose: () => void;
  onSubmit?: () => void;
};

export function UserProfileModal({
  userId: userIdProp,
  isOpen,
  preventCloseWhenFormInvalid,
  isEnableRole = false,
  onClose,
  onSubmit,
}: UserProfileModalProps) {
  const queryClient = useQueryClient();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const dataUserProfileDefaultRef = useRef({});
  const toast = useToast();
  const dataUserRolesDefaultRef = useRef([]);

  const { user: dataUser, isLoading: isLoadingUser } = useAuth0();

  const [userId, setUserId] = useState(userIdProp || dataUser?.sub || '');

  const { data: dataUserProfile, isFetching: isFetchingUserProfile } =
    useUserProfileByUserId(userId);

  const { updateUserRoles, deleteUserRoles } = useUpdateUserRoles();

  const reactHookFormRet = useUserProfileForm({
    resolver: yupResolver(modifyUserProfile),
  });
  const {
    handleSubmit,
    getValues,
    setValue,
    reset,
    trigger,
    watch,
    formState: { isSubmitSuccessful },
  } = reactHookFormRet;

  const rolesValue = watch('roles');

  const { mutateAsync } = useUpdateUserProfile(userId);

  const handleUpdateUserProfile = useCrudActionUserFeedback<UserProfileModify>({
    mutateAsync: () => mutateAsync(getValues()),
    actionType: 'UPDATE',
    successMessage: `Profile successfully updated`,
    successCallback: async () => {
      sendUpdateUserProfileEvent(userId);
      onSubmit?.();
      onClose();
      isEnableRole && (await updateDeleteUserRoles());

      queryClient.invalidateQueries({
        queryKey: ['users'],
      });
      queryClient.invalidateQueries({
        queryKey: ['userProfile', userId],
      });
    },
    startCallback: () => {
      setIsSubmitting(true);
    },
    finallyCallback: () => {
      setIsSubmitting(false);
    },
    setFieldError: reactHookFormRet.setError,
    mapFieldDtoErrorToRhfPath,
  });

  const handleCloseModal = async () => {
    if (preventCloseWhenFormInvalid && !isSubmitSuccessful) {
      const isFormValid = await trigger();
      if (isFormValid) {
        toast({
          status: 'error',
          title: 'Error',
          description: 'Please save your changes',
        });
      }
      return;
    }
    reset(dataUserProfileDefaultRef.current);
    setValue('roles', dataUserRolesDefaultRef.current);
    onClose();
  };

  const updateDeleteUserRoles = async () => {
    const roleIds = rolesValue?.length
      ? rolesValue
          .filter((roleFilter) => roleFilter.value !== '')
          .map((role: OptionSelect) => role.value)
      : [];

    const defaultRoleIds = dataUserRolesDefaultRef.current.length
      ? dataUserRolesDefaultRef.current
          .filter((roleFilter: any) => roleFilter.value !== '')
          .map((role: OptionSelect) => role.value)
      : [];

    defaultRoleIds.length && (await deleteUserRoles(userId, defaultRoleIds));
    roleIds.length && updateUserRoles(userId, roleIds);
  };

  useEffect(() => {
    if (userIdProp) setUserId(userIdProp);
  }, [userIdProp]);

  useEffect(() => {
    if (dataUserProfile) {
      const dataUserProfileGeneral = mapUserProfileToModify(dataUserProfile);
      dataUserProfileDefaultRef.current = dataUserProfileGeneral;
      for (const [key, value] of Object.entries(dataUserProfileGeneral)) {
        setValue(key as any, value);
      }
      const defaultRoles = dataUserProfile?.roles?.length
        ? dataUserProfile.roles
        : [];
      dataUserRolesDefaultRef.current = defaultRoles as any;
      setValue('roles', defaultRoles);
    }
  }, [dataUserProfile, setValue]);

  const dataUserSystem = useMemo(() => {
    return mapUserProfileToUserSystem(dataUserProfile);
  }, [dataUserProfile]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleCloseModal}
      showCloseButton={true}
      size={['full', null, '4xl']}
      modalContentProps={{
        'aria-label': 'User Profile Modal',
        height: '100%',
      }}
      scrollVisible={true}
    >
      {isLoadingUser || isFetchingUserProfile ? (
        <BigLoading />
      ) : (
        <FormProvider {...reactHookFormRet}>
          <form onSubmit={handleSubmit(handleUpdateUserProfile)}>
            <SimpleGrid columns={1} gap={[2, 8]}>
              <GridItem>
                <UserProfilePhoto photoSrc={dataUserProfile?.picture} />
              </GridItem>
              <GridItem>
                <SectionCollapsable title="General">
                  <UserProfileGeneral
                    emailAddress={dataUserProfile?.emailAddress}
                    startTabIndex={0}
                  />
                </SectionCollapsable>
              </GridItem>
              {isEnableRole ? (
                <GridItem>
                  <SectionCollapsable title="Role">
                    <UserProfileRole email={dataUserProfile?.emailAddress} />
                  </SectionCollapsable>
                </GridItem>
              ) : null}
              <GridItem>
                <SectionCollapsable title="System">
                  <UserProfileSystem data={dataUserSystem} />
                </SectionCollapsable>
              </GridItem>
              <GridItem>
                <ButtonGroup
                  wrapperProps={{ display: 'grid' }}
                  commonButtonProps={{ width: '100%' }}
                  buttons={[
                    {
                      label: 'Save',
                      variant: 'cta',
                      isLoading: isSubmitting,
                    },
                    {
                      label: 'Cancel',
                      handler: handleCloseModal,
                    },
                  ]}
                />
              </GridItem>
            </SimpleGrid>
          </form>
        </FormProvider>
      )}
    </Modal>
  );
}
