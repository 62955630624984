import { Flex, SimpleGrid, Tag } from '@chakra-ui/react';
import { useMemo } from 'react';
import { PaginatedTable } from 'components/PaginatedTable';
import { QueryState } from 'types/queryState';
import { NetworkOrderShipment, NetworkOrderDetails } from 'types/networkOrder';
import { Link } from 'components/Link';
import { Column } from 'react-table';
import { getBundlesFromShipment } from 'utils/networkOrdersUtils';
import { SalesOrderStatus } from 'types/salesOrders';

interface Props {
  order: NetworkOrderDetails;
}

export function ShipmentsTable({ order }: Props) {
  const columns = useMemo(
    () =>
      [
        {
          Header: 'Shipment Number #',
          accessor: 'shipmentNumber',
          width: 300,
          Cell: ({ value, row }) =>
            row.original.status === SalesOrderStatus.Cancelled ? (
              value
            ) : (
              <Link
                to={`/orders/${encodeURIComponent(value)}`}
                fontWeight="bold"
                _hover={{ textDecoration: 'underline' }}
              >
                {value}
              </Link>
            ),
        },
        {
          Header: 'Status',
          accessor: 'status',
          width: 350,
          Cell: ({ value, row: { original } }) => {
            const bundles = getBundlesFromShipment(original, order.lines);
            return bundles.length > 0 ? (
              <SimpleGrid gap={4} gridTemplateColumns="auto 1fr">
                {value}
                <Flex flexWrap="wrap" gap={1} columnGap={2}>
                  {bundles.map((bundle) => (
                    <Tag
                      key={bundle.sku}
                      size="sm"
                      variant="solid"
                      colorScheme="green"
                    >
                      BUNDLE: {bundle.sku}
                    </Tag>
                  ))}
                </Flex>
              </SimpleGrid>
            ) : (
              value
            );
          },
        },
      ] as Column<NetworkOrderShipment>[],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <PaginatedTable
      aria-label="Shipments Table"
      columns={columns}
      queryState={
        {
          data: order.shipments || [],
          isLoading: !order.shipments.length,
        } as QueryState<NetworkOrderShipment>
      }
      tableName="sales_order_table"
      isResizable
      isSticky
    />
  );
}
